import React from "react"
import Andrew from "@/assets/images/people/advisor_andrew_2.png"
import Yaniv from "@/assets/images/people/advisor_yaniv_2.png"
import Mike from "@/assets/images/people/mike.png"
import IconLinkedIn from "@/assets/icons/linkedin.svg"


const AboutUsMeetTheAdvisorySection: React.FC = () => {
    return (
        <div className={'container-about-us-advisory-team'}>
            <div className={'container content'}>
                <div className={'left'}>
                    <span className={'text heading h4'}>
                        Our Advisors
                    </span>
                </div>
                <div className={'right'}>
                    <div className={'team'}>
                        <div className={'team-member'}>
                            <div className={'team-member-image'}>
                                <img src={Andrew} alt={'Andrew'} />
                            </div>
                            <div className={'team-member-text'}>
                                <div className={'team-member-text-name'}>Andrew Sutherland</div>
                                <div className={'team-member-text-title'}>Advisor</div>
                                <div className={'team-member-linkedin'}>
                                    <a href={"https://www.linkedin.com/in/ajsland/"} target={"_blank"}>
                                        <img src={IconLinkedIn} alt={'linkedin'} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={'team-member'}>
                            <div className={'team-member-image'}>
                                <img src={Yaniv} alt={'neil'} />
                            </div>
                            <div className={'team-member-text'}>
                                <div className={'team-member-text-name'}>Yaniv Bernstein</div>
                                <div className={'team-member-text-title'}>Advisor</div>
                                <div className={'team-member-linkedin'}>
                                    <a href={"https://www.linkedin.com/in/ybernstein/"} target={"_blank"}>
                                        <img src={IconLinkedIn} alt={'linkedin'} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={'team-member'}>
                            <div className={'team-member-image'}>
                                <img src={Mike} alt={'Mike'} />
                            </div>
                            <div className={'team-member-text'}>
                                <div className={'team-member-text-name'}>Mike Rahmati</div>
                                <div className={'team-member-text-title'}>Advisor</div>
                                <div className={'team-member-linkedin'}>
                                    <a href={"https://au.linkedin.com/in/mikerahmati"} target={"_blank"}>
                                        <img src={IconLinkedIn} alt={'linkedin'} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsMeetTheAdvisorySection;
