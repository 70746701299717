import React from "react"

interface IProps {
    title?: string
    subtitle?: string
    paragraph?: string
}

const SectionText: React.FC<IProps> = ({title, subtitle, paragraph}: IProps) => {
    return (
        <div className={'section-text'}>
            <div className={'section-text-left'}>
                { title ? <div className={'section-text-left-title'}>
                    {title}
                </div>: null}
                { subtitle ? <div className={'section-text-left-subtitle'}>
                    {subtitle}
                </div> : null}
            </div>
            <div className={'section-text-right'}>
                { paragraph ? <div className={'section-text-right-paragraph'}>
                    {paragraph}
                </div>: null }
            </div>
        </div>
    )
}

export default SectionText
