import React from "react"
import TrailBlazerFeatureImage from "@/assets/images/trailblazer.svg"

export const FEATURE_ANCHOR_ID_TBZ = 'feature-trailblazer'

const TrailBlazer: React.FC = () => {
    return (
        <div id={FEATURE_ANCHOR_ID_TBZ} className={'feature-content'}>
            <div className={'title'}>
                <span className={'text'}>
                    TrailBlazer
                </span>
            </div>
            <div className={'paragraph-container'}>
                <span className={'paragraph'}>
                  Preventative policies are your primary control and first line of defence.
                  An additional layer of detective controls also strengthens security posture by providing audit traceability,
                  control assurance and additional runtime protection. Kivera has developed TrailBlazer,
                  a detective scanner which identifies misconfigurations in deployed cloud resources.
                  TrailBlazer uses the <b>same Rego policies</b> as Kivera’s preventative engine allowing you to seamlessly
                  switch controls from detection to prevention or have both running simultaneously.
                  TrailBlazer has coverage across most services in AWS, Google Cloud and Microsoft Azure.
                </span>
            </div>
            <div className={'image-container trailblazer-feature'}>
                <img className={'image trailblazer-feature'} src={TrailBlazerFeatureImage} alt={'trailblazer'}/>
            </div>
          <div className={'paragraph-container'}>
                <span className={'paragraph'}>
                  Using TrailBlazer, Kivera can provide a comprehensive cloud security report
                  to help you better understand and take control of your cloud security posture.{' '}
                  <a href={'/report'} className={'thick-link'}>
                        Find out more.
                  </a>
                </span>
          </div>
        </div>
    )
}

export default TrailBlazer
