import React from "react"
import MascotRobotSliders from "@/assets/images/mascot-robot-sliders.svg"

export const FEATURE_ANCHOR_ID_ID_AWARE = 'feature-identity-aware'

const IdentityAware: React.FC = () => {
    return (
        <div id={FEATURE_ANCHOR_ID_ID_AWARE} className={'feature-content'}>
            <div className={'title'}>
                <span className={'text'}>
                    Policies aligned to Identity.
                </span>
            </div>
            <div className={'paragraph-container'}>
                <span className={'paragraph'}>
                    Not all workloads are equal, especially when satisfying differing compliance requirements. Within Kivera, 
                    each workload receives a unique identity allowing you to associate differing sets of policies to each 
                    workload. Seamlessly manage exemptions, applying strict controls to your most critical workloads while 
                    relaxing controls for those that are less sensitive. Maintain regulatory compliance by building sets of 
                    policies aligned to regulator frameworks such as, NIST, APRA, MAS, PCI DSS and many more.
                </span>
            </div>
            <div className={'image-container'}>
                <img className={'image'} src={MascotRobotSliders} alt={'sample'}/>
            </div>
            {/*<div className={'link-container'}>*/}
            {/*    <a className={'link'} href={'#'}>*/}
            {/*        See how this works in a use case*/}
            {/*    </a>*/}
            {/*</div>*/}
        </div>
    )
}

export default IdentityAware
