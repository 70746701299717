import React from "react"
import { NavBar, NavBarMobile } from "@/layout"

const PricingHeroSection: React.FC = () => {
    return (
        <div className={'container-pricing-hero'}>
            <NavBarMobile/>
            <div className={'container'}>
                <NavBar/>
            </div>
        </div>
    )
}

export default PricingHeroSection;
