import React from "react"

interface IProps {
    className?: string
    subheading: string
    subheadingClassName?: string
    paragraph: JSX.Element
    paraClassName?: string
}

const SectionSubheadingParagraph: React.FC<IProps> = ({className, subheading, subheadingClassName, paragraph, paraClassName}: IProps) => {
    return (
        <div className={'section-subheading-paragraph ' + (className !== undefined ? className : '')}>
            <div className={'left'}>
                <div className={'subheading ' + (subheadingClassName !== undefined ? subheadingClassName : '')}>
                    <span className={'heading h6-5 secondary'}>
                        { subheading }
                    </span>
                </div>
            </div>
            <div className={'right'}>
                <div className={'paragraph ' + (paraClassName !== undefined ? paraClassName :  '')}>
                    { paragraph }
                </div>
            </div>
        </div>
    )
}

export default SectionSubheadingParagraph
