import React from "react"
import { SectionText } from "@/components"

const ProductSubheadingSection: React.FC = () => {
    return (
        <div className={'container-product-subheading'}>
                <div className={'container container-product-subheading-text'}>
                    <SectionText
                        subtitle={'Kivera provides deep visibility into every ' +
                        'cloud API request.'}
                        paragraph={'Combined with an extensible policy-as-code engine,' +
                        ' Kivera delivers unmatched cloud security capabilities focused' +
                        ' on prevention of misconfigured cloud resources at both build-time' +
                        ' and run-time.'}
                    />
                 </div>
        </div>
    )
}

export default ProductSubheadingSection;
