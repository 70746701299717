import React, { useEffect, useState } from "react"
import Carousel, { autoplayPlugin, slidesToShowPlugin } from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"

export type CarouselImage = {
    imgSrc: string
    imgAlt: string
}

interface IProps {
    ciContainerClassName?: string
    ciClassName?: string
    carouselImages: CarouselImage[]
}

const LogoCarousel: React.FC<IProps> = ({ carouselImages, ciClassName, ciContainerClassName }: IProps) => {
    const [value, setValue] = useState(0);

    const sliderImages = carouselImages.map((img, index) => {
        return (
            <div key={index}
                 className={'carousel-image-container ' + String(ciContainerClassName ?? '' )}
                 data-value={index}
            >
                <img key={index} src={img.imgSrc} alt={img.imgAlt} className={'carousel-image ' + String(ciClassName ?? '' )} />
            </div>
        )
    })


    // @ts-ignore
    return (
        <>
            <Carousel
                value={value}
                onChange={(val) => {setValue(val)}}
                plugins={[
                    'infinite',
                    {
                        resolve: slidesToShowPlugin,
                        options: {
                            numberOfSlides: 10
                        }
                    },
                    {
                        resolve: autoplayPlugin,
                        options: {
                            interval: 2000,
                        }
                    }
                ]}
                animationSpeed={1000}
                breakpoints={{
                    400: {
                        plugins: [
                            'infinite',
                            {
                                resolve: slidesToShowPlugin,
                                options: {
                                    numberOfSlides: 4
                                }
                            },
                            {
                                resolve: autoplayPlugin,
                                options: {
                                    interval: 2000,
                                }
                            }
                        ]
                    },
                    480: {
                        plugins: [
                            'infinite',
                            {
                                resolve: slidesToShowPlugin,
                                options: {
                                    numberOfSlides: 6
                                }
                            },
                            {
                                resolve: autoplayPlugin,
                                options: {
                                    interval: 2000,
                                }
                            }
                        ]
                    },
                    600: {
                        plugins: [
                            'infinite',
                            {
                                resolve: slidesToShowPlugin,
                                options: {
                                    numberOfSlides: 8
                                }
                            },
                            {
                                resolve: autoplayPlugin,
                                options: {
                                    interval: 2000,
                                }
                            }
                        ]
                    },
                    768: {
                        plugins: [
                            'infinite',
                            {
                                resolve: slidesToShowPlugin,
                                options: {
                                    numberOfSlides: 8
                                }
                            },
                            {
                                resolve: autoplayPlugin,
                                options: {
                                    interval: 2000,
                                }
                            }
                        ]
                    },
                    1024: {
                        plugins: [
                            'infinite',
                            {
                                resolve: slidesToShowPlugin,
                                options: {
                                    numberOfSlides: 10
                                }
                            },
                            {
                                resolve: autoplayPlugin,
                                options: {
                                    interval: 2000,
                                }
                            }
                        ]
                    }
                }}
            >
                { sliderImages }
            </Carousel>
        </>
    )
}

export default LogoCarousel