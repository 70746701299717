import React from "react"
import Logo from "@/assets/images/kivera-logo-white.svg"
import TwitterIcon from "@/assets/images/social-twitter-small-black-bg.svg"
import LinkedInIcon from "@/assets/images/social-linkedin-small-black-bg.svg"
import { Link } from "gatsby"
import { PATH_ABOUT_US, PATH_DOCS, PATH_HOME, PATH_PRICING, PATH_PRIVACY, PATH_PRODUCT, PATH_USECASES } from "@/layout"

const Footer: React.FC = () => {
    return (
    <div className={'container section-footer'}>
        <div className={'section-footer-left'}>
            <Link to={'/'}>
                <img className={'section-footer-left-logo'} src={Logo} alt={'Kivera logo'} />
            </Link>
            <div className={'section-footer-left-social'}>
                <a href={`${process.env.LINKEDIN_URL}`} target={'_blank'}>
                    <div className={'section-footer-left-social-linkedin'}>
                        <img className={'section-footer-left-social-linkedin-image'} src={LinkedInIcon} alt={'linkedin-icon'}/>
                    </div>
                </a>
                <a href={`${process.env.TWITTER_URL}`} target={'_blank'}>
                    <div className={'section-footer-left-social-twitter'}>
                        <img className={'section-footer-left-social-twitter-image'} src={TwitterIcon} alt={'facebook-icon'}/>
                    </div>
                </a>
            </div>
        </div>
        <div className={'section-footer-right'}>
            <div className={'section-footer-right-links'}>
                <div className={'section-footer-right-links-part1'}>
                    <ol>
                        <li><Link className={'link'} to={PATH_HOME}>Overview</Link></li>
                        <li><Link className={'link'} to={PATH_PRODUCT}>Product</Link></li>
                        <li><Link className={'link'} to={PATH_USECASES}>Use Cases</Link></li>
                        {/*<li><Link className={'link'} to={PATH_DOCS}>Docs</Link></li>*/}
                        <li><Link className={'link'} to={PATH_PRICING}>Pricing</Link></li>
                        <li><Link className={'link'} to={PATH_ABOUT_US}>About Us</Link></li>
                    </ol>
                </div>
                <div className={'section-footer-right-links-part2'}>
                    <ol>
                        <li>
                            <a className={'link'} href={'https://www.linkedin.com/company/kivera/jobs'} target={'_blank'}>
                            Careers</a>
                        </li>
                        <li><Link className={'link'} to={PATH_PRIVACY}>Privacy Policy</Link></li>
                    </ol>
                </div>
            </div>
            <span className={'section-footer-right-copyright'}>
                Kivera &copy;2021 All rights reserved
            </span>
            <span className={'section-footer-right-copyright'}>
                <b>Patent Pending: No. 62/984,725</b>
            </span>            
            
        </div>
    </div>
)
}
export default Footer
