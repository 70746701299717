import React from "react"
import CloudExfiltration from "@/assets/images/cloud-exfiltration.svg"

export const FEATURE_ANCHOR_ID_DLP = 'feature-data-loss-prevention'

const DataLossPrevention: React.FC = () => {
    return (
        <div id={FEATURE_ANCHOR_ID_DLP} className={'feature-content'}>
            <div className={'title'}>
                <span className={'text'}>
                    Data Loss Prevention (DLP)
                </span>
            </div>
            <div className={'paragraph-container'}>
                <span className={'paragraph'}>
                    The most significant impact of a cloud resource misconfiguration is data exfiltration. 
                    Malicious actors use misconfigured cloud resources such as storage buckets, to exfiltrate sensitive 
                    data leading to considerable financial, regulatory and reputational damages.
                </span>
            </div>
            <div className={'paragraph-container'}>
                <span className={'paragraph'}>
                    However, it's not just persistent storage that requires protection, a significant number of cloud 
                    services can be used to exfiltrate data including message queuing, streaming, container, database and compute services. 
                    Any misconfiguration could ultimately result in a data breach exposing your organisation.
                </span>
            </div>
            <div className={'image-container'}>
                <img className={'image'} src={CloudExfiltration} alt={'sample'}/>
            </div>
            <div className={'paragraph-container'}>
                <span className={'paragraph'}>
                    Traditional content inspection DLP solutions scan and identify a potential breach often long after the data is gone. 
                    Kivera, however, differs by identifying the source and destination of a data transfer as the request is made. 
                    Based on source identity, Kivera ensures the destination is an authorised and trusted cloud resource blocking any requests 
                    that do not match a fully customisable criteria. Kivera's DLP solution supports every cloud provider service, allowing you 
                    to protect your organisation and stop data exfiltration before it happens.
                </span>
            </div>
            {/* <div className={'link-container'}>
                <a className={'link'} href={'#'}>
                    See how this works in a use case
                </a>
            </div> */}
        </div>
    )
}

export default DataLossPrevention