import React from "react"
import { makeClassName } from "@/lib"

interface IProps {
    className?: string
    img: string
    imgAlt: string
    imgClassName?: string
    imgStyle?: any
    textClassName?: string
    title: JSX.Element
    titleClassName?: string
    paragraph: JSX.Element
    paragraphClassName?: string
    reverse?: boolean
}

const SectionImageText: React.FC<IProps> = ({className, img, imgAlt, imgClassName, imgStyle, textClassName, title,
                                                titleClassName, paragraph, paragraphClassName, reverse}: IProps) => {

    const image = (
        <div className={'image-container '+ (!reverse ? '' : 'reverse') }>
            <img
                className={ 'image ' + (imgClassName !== undefined ? imgClassName : '')}
                src={img}
                alt={imgAlt}
                style={(imgStyle !== undefined ? imgStyle : {})}
            />
        </div>
    )

    const para = (
        <div className={'paragraph-container ' + (textClassName !== undefined ? textClassName : '')}>
            <span className={'title ' + (titleClassName !== undefined ? titleClassName : '') }>{ title }</span>
            <span className={'paragraph ' + (paragraphClassName !== undefined ? paragraphClassName : '') }>{ paragraph }</span>
        </div>
    )


    return (
        <div className={'section-image-text ' + (className !== undefined ? className : '')}>
            <div className={'container image-text-container ' + (!reverse ? '' : ' reverse ')}>
                { !reverse ? <>{image}{para}</> : <>{para}{image}</>}
            </div>
        </div>
    )
}

export default SectionImageText
