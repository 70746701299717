import React from "react"
import { LineSpacer, OrderedList, Paragraph, UnorderedList } from "@/components"

const PrivacyPolicySection: React.FC = () => {
    return (
        <div className={'container-privacy-policy'}>
            <div className={'container'}>
                <div className={'section-privacy-policy'}>
                    <h3 className={'paragraph medium bold'}>Kivera Inc. Privacy Policy</h3>
                    <span className={'paragraph small'}>
                        Kivera Corporation and its affiliates and subsidiaries ( collectively “us”, “we”, or “our”) operates
                        the <a href={'https://www.kivera.io'} className={'color-primary'}>www.kivera.io</a> website and provides cloud security software (the “Service”).
                    </span>
                    <LineSpacer/>

                    <Paragraph
                        title={'Purpose of this Privacy Notice'}
                        paras={[
                            'The purpose of this privacy notice is to inform our customers and clients of our commitment to personal data protection, trust and compliance with privacy protection laws (including the GDPR and PIPEDA) (“Data Protection Laws”). As a global organization, we recognize that Data Protection Laws may be different and as a practice we have adopted those policies which reflect the most stringent protections for the collection, use, and handling of personal data.  This Privacy Policy informs you of our policies regarding the collection, use and disclosure of Personal Information when you use our Services. We strive to engage in “privacy by design”.',
                            'We are committed to providing our clients and customers (“you”, “your” or “them”) with exceptional service in securing cloud services. Providing with exceptional service in securing cloud services may involve the collection, use and, at times, the disclosure of your Personal Information (as set out below). Protecting your Personal Information is one of our highest priorities. We will inform you of why and how we collect, use and disclose Personal Information; obtain your consent, as required by law; and handle and use Personal Information according to applicable law. Our privacy commitment includes ensuring the accuracy, confidentiality, and security of your Personal Information and allowing you to request access to, correction, and if requested, removal of, your personal information.'
                        ]}/>

                    <Paragraph
                        title={'Data Protection Principles'}
                        paras={[
                        'We will comply with the following data protection principles when processing Personal Information:'
                    ]}/>

                    <OrderedList
                        className={'paragraph small'}
                        list={[
                         'we will process personal information lawfully, fairly and in a transparent manner;',
                         'we will collect personal information for specified, explicit and legitimate purposes only, and will not process it in a way that is incompatible with those legitimate purposes;',
                         'we will only process the personal information that is adequate, relevant and necessary for the relevant purposes;',
                         'we will keep accurate and up-to-date personal information and take reasonable steps to ensure that inaccurate personal information is deleted or corrected without delay',
                         'we will keep personal information in digital form for no longer than is necessary for the purposes for which the information is processed; and',
                         'we will take appropriate technical and organizational measures to ensure that personal information is kept secure and protected against unauthorized or unlawful processing, and against accidental loss, destruction or damage.'
                        ]}
                    />
                    <Paragraph
                        title={'The Personal Data we collect about You'}
                        paras={[
                            'Personal data, or personal information, means any information about an individual from which that person can be identified (“Personal Information”). It does not include data where the identity has been removed (anonymous data).',
                            'We may collect, use, store and transfer different kinds of Personal Information about you may include but is not limited to your identity (which includes your name, username(s), martial status, title, date of birth and gender or non-binary designation), contact information, financial information, and data associated with your use of our Services and this website (including IP address information, browser information, platform, software, and usage data. In some instances, we may aggregate data and where we do so, we will ensure that your Personal Information cannot be determined from the aggregated data, and where it is it will be treated as Personal Information under this privacy policy.',
                            'We do not collect special or sensitive personal data.'
                        ]}
                    />

                    <Paragraph
                        title={'Collection of your Personal Information'}
                        paras={[
                            'We will only collect Personal Information that is necessary to fulfill the following legal purposes relating to the provision of Services to you or in relation to our contractual, legal, or contractual obligations:'
                        ]}
                    />

                    <UnorderedList
                        className={'paragraph small'}
                        list={[
                            'To verify identity;',
                            'To identify your preferences;',
                            'To open and manage an account;',
                            'To ensure you receive a high standard of service;',
                            'To meet regulatory requirements; or',
                            'Other legal reasons as apply to the goods and services requested.'
                        ]}
                    />

                    <Paragraph
                        paras={[
                            'As you interact with our website and other Services, we may automatically collect technical and usage data about you or your equipment, visits to pages of our website and preferences. We can collect this Personal Information about you through the use of cookies, server logs, and other technical products. You may review our policy on cookie usage below.'
                        ]}
                    />

                    <Paragraph
                        title={'Use of your Personal Information'}
                        paras={[
                            'The use of your Personal Information is generally required to fulfill contractual obligations to you, where it is related to legitimate interests about our Services or those of a third party where those interests do not infringe on your fundamental rights and where we are required to comply with a legal or regulatory requirement. Unless we intend on sending marketing material to you, we do not rely on consent in order to use and process your Personal Information that you have freely provided to us. you may withdraw your consent to any marketing activities at any time by contacting the Privacy Protection Officer below.'
                        ]}
                    />

                    <Paragraph
                        title={'Personal Information Purposes'}
                        paras={[
                            'In order to provide you with the Services, products, and access and the use of this website, we have to use your Personal Information. For example, when you provide your Personal Information to us through a web form or to request information about a product or Service that we provide, you are providing identity and contract information to us, and in some cases may provide financial information to us. Our use of such Personal Information is directly related to mutual legitimate interests and/or related to a contractual relationship between you and us. In general, our use and processing of your Personal Information arises from contractual, legitimate interests, and/or our obligation to comply with laws.'
                        ]}
                    />

                    <Paragraph
                        title={'What We Do NOT Do with your Personal Information'}
                        paras={[
                            'We will not sell your Personal Information to other parties. We will not alter your Personal Information or disclose it without your consent (unless required by law). We will not keep your Personal Information for longer than is required for its purpose and use. We will retain your personal information until either: it is no longer reasonably necessary or legally required to maintain a record of such information, or you request its destruction.'
                        ]}
                    />

                    <Paragraph
                        title={'Corrections and Your Right to be Forgotten'}
                        paras={[
                            'You have the right to request a copy of the personal information retained about you by Kivera. In some cases, your ability to access or control your personal data will be limited, as required or permitted by applicable law. If you would like to obtain a copy of such records, please make your request in writing to our Privacy Officer at the contact information contained below. Please include sufficient detail to identify your personal information. We may contact you to verify the information provided and/or your identity, where applicable. In any case, we will respond to your request in writing as quickly as possible, and no later than 30 days after receiving your written request. We reserve the right to refuse such request where necessary and permitted or required by applicable laws.',
                            'We will make reasonable efforts to ensure that your Personal Information is accurate and complete. You may request correction to your Personal Information to ensure its accuracy and completeness. You may also request at any time that you be “forgotten”. This means we will erase all Personal Information about you in our possession (subject to applicable laws).  A request must be in writing and provide sufficient detail to identify your Personal Information and the correction or deletion being sought.',
                            'We are committed to ensuring the security of your Personal Information and will maintain appropriate administrative, physical, and technical safeguards for protection of the security, confidentiality and integrity of your Personal Information.  To achieve this goal, we may use passwords, encryption, firewalls, restricted employee access or other methods, in our discretion. We will use appropriate security measures when destroying your Personal Information such as shredding documents or deleting electronically stored information, in our discretion.'
                        ]}
                    />

                    <Paragraph
                        title={'Log Data'}
                        paras={[
                            'When browsing on our website, we may also collect information that your browser sends whenever you visit our Service (“Log Data”). This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages and other statistics.'
                        ]}
                    />
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicySection;
