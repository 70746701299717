import React from "react"
import PipedriveForm from "../../../components/pipedrive-form";

const AboutUsContactUsSection: React.FC = () => {
  return (
    <div className={'container-about-us-contact-us'}>
      <div className={'container content'}>
        <div className={'left'}>
          <div className={'top'}>
            <span className={'text heading h4'}>
              Contact Us
            </span>
          </div>
          {/* <div className={'bottom'}>
           <div className={'email'}>
             <span className={'text'}>
              Email
            </span>
             <a href={"mailto:info@kivera.io"} className={'email-id'}>
               info@kivera.io
            </a>
           </div>
           <div className={'follow-us'}>
              <span className={'text'}>
                Follow Us
              </span>
              <a className={'external-link'} href={'https://www.linkedin.com/company/kivera/'} target={"_blank"}>
                LinkedIn
              </a>
             <a className={'external-link'} href={'https://twitter.com/kivera_io'} target={"_blank"}>
               Twitter
             </a>
           </div>
          </div> */}
        </div>
        <div className={'right'}>
          <div className={'contact-us-container'}>
            <div className={'form'}>
              <PipedriveForm
                key={'13323ec18f63'}
                formID={'13323ec18f63'}
                formURL={`${process.env.CU_PIPEDRIVE_FORM_URL}`}
                className={'pipedriveContactWebForms'}
                iframeClassName={'pipedriveContactWebForms-iframe'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUsContactUsSection;
