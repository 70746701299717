import React from "react"
import { Link } from 'gatsby';
import { NavBar, NavBarMobile, PATH_PRODUCT } from "@/layout"

const OverviewHeroSection: React.FC = () => {
    return (
       <div className={'container-overview-hero '}>
           <NavBarMobile/>
           <div className={'container section-hero-overview'}>
               <NavBar/>
                <div className={'section-hero-overview-left-content'}>
                    <span className={'section-hero-overview-left-content-paragraph-text heading h3'}>
                        Simplified Cloud Security
                    </span>

                   <Link to={PATH_PRODUCT}>
                       <div className={'section-hero-overview-left-content-cta-button'}>
                            <span className={'section-hero-overview-left-content-cta-button-text'}>
                                Find out more
                            </span>
                       </div>
                   </Link>
               </div>
           </div>
       </div>
    )
}

export default OverviewHeroSection;
