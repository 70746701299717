import React from "react"

const UsecasesHeaderSection: React.FC = () => {
    return (
        <div className={'container-usecases-header'}>
            <div className={'container content'}>
                <div className={'left'}>
                    <span className={'text'}>
                        Use Cases
                    </span>
                </div>
            </div>
        </div>
    )
}

export default UsecasesHeaderSection;
