import React from "react"

const WhitepaperSection: React.FC = () => {
    return (
        <div className={'container-whitepaper'}>
            <div className={'container section-whitepaper'}>
            <iframe
              width="100%"
              height="750"
              src="https://www.youtube.com/embed/7WCaJ_XEWMc?autoplay=1&mute=1&modestbranding=1&controls=1&rel=0&cc_load_policy=1 "
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={true}
            />
                {/* <span className={'section-whitepaper-heading-text heading secondary h5-5'}>
                    Kivera is a <span className={'underline-expanding'}>cloud inspection proxy</span>{' '}
                    that enforces granular policies across <span className={'underline-expanding'}>any</span> {' '}
                    cloud provider, <span className={'underline-expanding'}>any</span> service and <span className={'underline-expanding'}>any</span>{' '}
                    deployment tool.
                </span> */}
            </div>
        </div>
    )
}

export default WhitepaperSection;
