import React from "react"

interface IProps {
    img: string
    imgAlt: string
    imgWidth?: string
}

const SectionImageFullsize: React.FC<IProps> = ({img, imgAlt, imgWidth}: IProps) => {
    return (
        <div className={'section-image-fullsize'}>
            <img className={'image-fullsize'} src={img} alt={imgAlt} style={(imgWidth !== undefined ? { width: imgWidth} : {})}/>
        </div>
    )
}

export default SectionImageFullsize
