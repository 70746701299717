import React, { useState } from "react"

interface IProps {
    img: string
    imgAlt: string
    imgWidth?: string
    paragraph: JSX.Element
}

const DifferenceItemSection: React.FC<IProps> = ({ img, imgAlt, imgWidth, paragraph} : IProps) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false)

    return (
        <div className={'difference-item'}>
            <div className={'paragraph'}>
                <span className={'text'}>
                    { paragraph }
                </span>
            </div>
            <div className={'img-container'}>
                { imgWidth ?
                    <img className={'image'} src={img} alt={imgAlt} style={{width: imgWidth}} /> :
                    <img className={'image'} src={img} alt={imgAlt} onLoad={() => setIsImageLoaded(true)} style={ isImageLoaded ? {} : {display: "none"}} />}

            </div>
        </div>
    )
}

export default DifferenceItemSection