import React, { ReactElement } from "react"
import { WrapRootElementBrowserArgs } from "gatsby"
import { SEO } from "@/components"
import "@/styles/global.scss"
import { DefaultLayout } from "@/layout"

const Main = (props: WrapRootElementBrowserArgs): ReactElement => {
    return (
        <div className={'root-element'}>
            <SEO />
            <DefaultLayout children={props.element} />
        </div>
    )
}

export default Main
