import React from "react"
import { NavBar, NavBarMobile } from "@/layout"

const AboutUsHeroSection: React.FC = () => {
    return (
        <div className={'container-about-us-hero'}>
            <NavBarMobile/>
            <div className={'container'}>
                <NavBar/>
            </div>
        </div>
    )
}

export default AboutUsHeroSection;
