import React from "react"

const LineSpacer: React.FC = () => {
    return (
        <span className={'paragraph-line-spacer'}>
            <br/>
        </span>
    )
}

export default LineSpacer