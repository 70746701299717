import React from "react"

const AboutUsSection: React.FC = () => {
  return (
    <div className={'container-about-us'}>
      <div className={'container content'}>
        <div className={'left'}>
                    <span className={'text heading h4'}>
                        About us
                    </span>
        </div>
        <div className={'right'}>
                    <span className={'text paragraph md'}>
                        With a passion for solving problems and years of experience delivering cloud solutions for
                        highly regulated clients, our team is dedicated to making your cloud journey faster, simpler and more secure.
                    </span>
                </div>
      </div>
    </div>
  )
}

export default AboutUsSection;
