import React from "react"
import TaggingImg from "@/assets/images/tagging.svg"

export const FEATURE_ANCHOR_ID_TAGGING = 'feature-tagging'

const Tagging: React.FC = () => {
    return (
        <div id={FEATURE_ANCHOR_ID_TAGGING} className={'feature-content'}>
            <div className={'title'}>
                <span className={'text'}>
                    Tagging
                </span>
            </div>
            <div className={'paragraph-container'}>
                <span className={'paragraph'}>
                    Embed your organisational metadata as <b>key</b>:<b>value</b> tags into each level of the Kivera hierarchy. 
                    Tags add additional context to an API request for example business unit, service details, 
                    data classification or even control IDs from internal policy documents. Kivera's policy engine 
                    references and validates against tags giving you greater control over every API request.
                </span>
            </div>
            <div className={'image-container'} style={
                {
                    paddingBottom: '30px',
                    display: 'flex',
                }
            }>
                <img className={'image'} src={TaggingImg} alt={'sample'} style={{width: '100%'}}/>
            </div>
            {/*<div className={'link-container'}>*/}
            {/*    <a className={'link'} href={'#'}>*/}
            {/*        See our deployment architecture*/}
            {/*    </a>*/}
            {/*</div>*/}
        </div>
    )
}

export default Tagging