import React, { useEffect } from "react"
import PipedriveForm from "@/components/pipedrive-form"
import { useLocation } from "@reach/router"

const CTAFreeReportSection: React.FC = () => {
    const location = useLocation()

    useEffect(()=> {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({behavior: "smooth"})
            }
        } else {
            window.scrollTo({top:0,left:0, behavior: "smooth"})
        }
    }, [location])

    return (
        <section id="report" className={'section-container-cta'}>
            <div className={'cta-content'}>
                <div className={'request'}>
                    <h4 className={'text heading h4'}>
                        Request Assessment
                    </h4>
                </div>
                <PipedriveForm
                  key={'qUtAnSsnS1Qb'}
                  formID={'qUtAnSsnS1Qb'}
                  formURL={`${process.env.FREE_REPORT_PIPEDRIVE_FORM_URL}`}
                  className={'pipedriveWebForms cta-report'}
                  iframeClassName={'pipedriveWebForms-iframe'}
                />
            </div>
        </section>
    )
}

export default CTAFreeReportSection;
