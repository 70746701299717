import React from "react"
import PLaceholder from "@/assets/images/placeholder_fuschia.svg"
import KiveraDeployment from "@/assets/images/Kivera-Deployment.svg"

export const FEATURE_ANCHOR_ID_HYB_DEP = 'feature-hybrid-saas-deployment'

const HybridDeployment: React.FC = () => {
    return (
        <div id={FEATURE_ANCHOR_ID_HYB_DEP} className={'feature-content'}>
            <div className={'title'}>
                <span className={'text'}>
                    Hybrid-SaaS Deployment
                </span>
            </div>
            <div className={'paragraph-container'}>
                <span className={'paragraph'}>
                    Using Kivera Hybrid, deploy Kivera within your own network where you control the 
                    cloud inspection proxy and the logs it generates. Kivera Hybrid keeps the most 
                    sensitive operations within your control while connecting to our SaaS-hosted control 
                    plane for configuration of the service. Kivera never sees your traffic, API requests or SSL certificates.
                </span>
            </div>
            <div className={'paragraph-container'}>
                <span className={'paragraph'}>
                Kivera also supports infrastructure as code, use our Terraform provider to deploy, manage 
                and configure the proxy. The proxy supports multiple platforms (Linux, Windows, OS X) 
                and can be run on a wide range of infrastructure such as on-premise physical hardware, VMs 
                (Vmware ESX, Hyper-V), cloud instances (EC2, GCE) and container platforms (GKE, EKS, AKS).
                </span>
            </div>

            <br/><br/>
            <div className={'image-container'}>

               <img className={'image hybrid'} style={{width: "100%"}} src={KiveraDeployment} alt={'sample'}/>

            </div>
        </div>
    )
}

export default HybridDeployment