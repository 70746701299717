import { useState, useEffect } from "react"

export const STICKY_STATUS_ORIGINAL = 0
export const STICKY_STATUS_POSITION = 1
export const STICKY_STATUS_RELEASED = 2

interface StickyStatus {
    status: number
}

export function useSticky() {
    const [stickyStatus, setStickyStatus] = useState(STICKY_STATUS_ORIGINAL)

    const onStickyChange = (stickyStatus: StickyStatus) => {
        setStickyStatus(stickyStatus.status)
    }
    return [stickyStatus, onStickyChange]
}

export function hasWindow() {
    const [isWindow, setIsWindow] = useState(false)

    useEffect(() => {
        setIsWindow(true);
        return ()=> setIsWindow(false);
    }, [])

    return isWindow
}