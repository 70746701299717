import React from "react"
import KiveraScreenshot from "@/assets/images/product-hero-screenshot.svg"
import ProductFullScreenshot from "@/assets/images/full-size/product-screenshot.svg"
import { NavBar, NavBarMobile } from "@/layout"
import { SectionImageFullsize } from "@/components"

const ProductHeroSection: React.FC = () => {
    return (
        <div className={'container-product-hero'}>
            <NavBarMobile/>
            <div className={'container'}>
                <NavBar/>
                <div className={'section-product-hero'}>
                    <div className={'section-product-hero-left-content'}>
                        <span className={'section-product-hero-left-content-paragraph-text heading h4'}>
                            Kivera is the next generation of cloud security tooling.
                        </span>
                    </div>
                    <div className={'section-product-hero-bottom-content'}>
                        <SectionImageFullsize
                            img={ProductFullScreenshot}
                            imgAlt={'misconfiguration occured'}
                        />
                    </div>
                    <div className={'section-product-hero-right-content'}>
                        <div className={'section-product-hero-right-content-image-container'}>
                            <img className={'section-product-hero-right-content-image'}
                                 src={KiveraScreenshot} alt={'Kivera product Screenshot'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductHeroSection;
