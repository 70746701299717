import React from "react"

interface IProps {
    list: string[]
    className?: string
}

const OrderedList: React.FC<IProps> = ({list, className}: IProps) => {
    return (
        <ol className={'ordered-list ' + (className !== undefined ? className : '')}
            style={{
                display: 'block',
                listStyleType: '1',
                marginBlockStart: '1em',
                marginBlockEnd: '1em',
                marginInlineStart: '0px',
                marginInlineEnd: '0px',
                paddingInlineStart: '40px'
            }}
        >
            { list.map((item, index) => {
                return (
                    <li key={String(index)} className={'ordered-list-item'} style={{listStyleType: '1'}}>
                        { item }
                    </li>
                )
            }) }
        </ol>
    )
}

export default OrderedList

