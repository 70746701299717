import React, { useState } from "react"
import Logo from "@/assets/images/kivera-logo-black.svg"
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { PATH_ABOUT_US, PATH_HOME, PATH_PRICING, PATH_PRODUCT, PATH_USECASES } from "@/layout/navigation"
import BurgerMenu from "@/assets/icons/burger-menu.svg"
import CrossMenu from "@/assets/icons/cross.svg"
import Sticky from "react-stickynode"
import { STICKY_STATUS_RELEASED, useSticky } from "@/lib"


interface IProps {
    className?: string
}

const NavBarMobile: React.FC<IProps> = ({ className }:IProps) => {
    const location = useLocation()

    const isPathActive = (path: string) => {
        return path === location.pathname
    }

    const [menuActive, setMenuActive] = useState(false)
    const [stickyStatus, onStickyChange] = useSticky()

    const makeMenu = (): JSX.Element => {
        return (
            <div className={'burger-container'}>
                <div className={'menu-container'}>
                    <div className={'menu-item'}>
                        <Link to={PATH_HOME}>
                            <span className={'text ' + (isPathActive(PATH_HOME) ? 'link-active' : '') }>Overview</span>
                        </Link>
                    </div>
                    <div className={'menu-item'}>
                        <Link to={PATH_PRODUCT}>
                            <span className={'text ' + (isPathActive(PATH_PRODUCT) ? 'link-active' : '') }>Product</span>
                        </Link>
                    </div>
                    <div className={'menu-item' }>
                        <Link to={PATH_USECASES}>
                            <span className={'text ' + (isPathActive(PATH_USECASES) ? 'link-active' : '') }>Use Cases</span>
                        </Link>
                    </div>
                    <div className={'menu-item'  }>
                        <Link to={PATH_PRICING}>
                            <span className={'text ' + (isPathActive(PATH_PRICING) ? 'link-active' : '')  }>Pricing</span>
                        </Link>
                    </div>
                    <div className={'menu-item' }>
                        <Link to={PATH_ABOUT_US}>
                            <span className={'text ' + (isPathActive(PATH_ABOUT_US) ? 'link-active' : '')  }>About Us</span>
                        </Link>
                    </div>
                </div>
                <div className={'menu-divider'}>
                </div>
                <div className={'request-demo' }>
                    <Link to="/report">
                        <div className={'cta-free-report-button'}>
                            <span className={'text'}>
                                Security Report
                            </span>
                        </div>
                    </Link>
                    <Link to="/#request" onClick={() => { setMenuActive(false) }}>
                        <div className={'cta-button'}>
                            <span className={'text'}>
                                Request Demo
                            </span>
                        </div>
                    </Link>
                </div>
            </div>
        )
    }

    return (
        <Sticky onStateChange={onStickyChange} bottomBoundary='#request' innerZ={9999}>
            {/*<div>*/}
            {/*    <h3>Width { width } and Resolution Width { widthCSS } </h3>*/}
            {/*</div>*/}
            <div className={'navbar-mobile ' + (stickyStatus === STICKY_STATUS_RELEASED ? ' scrolling ' : '')  + String(className ?? '')} >
                <div className={'mobile-container'}>
                    <div className={'top-bar'}>
                        <div className={'logo-container'}>
                            <Link to={'/'}>
                                <img className={'logo'} src={Logo} alt={'Kivera logo'} />
                            </Link>
                        </div>
                        <div className={'burger-menu-icon-container'} style={ menuActive ? { display: 'none'} : {} }>
                            <a href={'#'} onClick={() => { setMenuActive(true)}}>
                                <img className={'burger-menu'} src={BurgerMenu} alt={'Burger Menu'} />
                            </a>
                        </div>
                        <div className={'cross-menu-icon-container'} style={ !menuActive ? { display: 'none'} : {} }>
                            <a href={'#'} onClick={() => { setMenuActive(false)}}>
                                <img className={'cross-menu'} src={CrossMenu} alt={'Cross Menu'} />
                            </a>
                        </div>
                    </div>
                    { menuActive ? makeMenu() : null}
                </div>
            </div>
        </Sticky>
    )
}

export default NavBarMobile
