import React from "react"
import { Link } from "gatsby"

const PricingContactUsSection: React.FC = () => {
    return (
        <div className={'container-pricing-contact-us'}>
            <div className={'container content'}>
                <div className={'left'}>
                    <span className={'text heading h4'}>
                        Contact us for pricing
                    </span>
                </div>
                <div className={'right'}>
                    <span className={'text paragraph md'}>
                        We're currently in private preview. If you'd like to join this program, please {' '}
                        <Link className={'request-demo paragraph md bold'} to={'/#request'}>request a demo</Link>
                        {' '}to speak to our team
                    </span>
                </div>
            </div>
        </div>
    )
}

export default PricingContactUsSection;
