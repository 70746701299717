import React from "react"

interface IProps {
    img: string
    imgAlt: string
    imgWidth?: string
    title: string
    paragraph: string
}

export const PricingIncludedSubSection: React.FC<IProps> = ({img, imgAlt, imgWidth, title, paragraph}: IProps) => {
    return (
        <div className={'container-pricing-included-sub-section'}>
           <div className={'left'}>
               <img className={'image'} alt={imgAlt} src={img} style={ imgWidth ? {width: imgWidth} : {}}/>
           </div>
            <div className={'right'}>
                <div className={'title-container'}>
                    <span className={'title heading h6-5 secondary'}>{title}</span>
                </div>
                <div className={'paragraph-container'}>
                    <span className={'text paragraph lg'}>{paragraph}</span>
                </div>
            </div>
        </div>
    )
}
