import React, { useState } from "react"
import Preventative from "@/assets/images/preventative.svg"
import MascotRobots from "@/assets/images/mascot-robots.svg"
import ServiceCoverage from "@/assets/images/service-coverage.svg"
import { DifferenceItemSection } from "@/layout"

const ProductWhatMakesUsDifferentSection: React.FC = () => {

    const preventativeDesc = <>
                When dealing with sensitive workloads, the consequences of a single mistake can be {' '}
                    <a href={'/pricing#misconfigurations-in-the-news'} className={'thick-link'}>
                        considerable
                    </a>.
                While other tools can only detect misconfigurations anywhere from tens of minutes to hours later,
                Kivera ensures the misconfiguration never happens, keeping your data and organisation safe.
          </>
    
    const differenceList = [
        {
            id: 'pre-over-det',
            title: 'Preventative over Detective',
            desc: preventativeDesc,
            img: Preventative,
            imgAlt: 'Preventative',
            imgWidth: '97%'
        },
        {
            id: 'enterprise',
            title: 'Built for Enterprise',
            desc: 'Kivera is full of enterprise features, ensuring smooth integration into your new and existing cloud consumption patterns. From our hybrid deployment approach, to extensive tagging capability and full policy-as-code support, Kivera has dozens of features focused on making enterprise cloud consumption simpler and more secure.',
            img: MascotRobots,
            imgAlt: 'enterprise coverage',
            imgWidth: '37%'
        },
        {
            id: 'full-coverage',
            title: 'Complete Service for Coverage',
            desc: "The growth of cloud provider features and services is exponential but Kivera's policy engine has been developed to keep pace. As each new API is enabled by the cloud provider, you'll immediately be able to develop your own custom policies to secure it. Kivera provides unmatched, zero-lag feature support and complete coverage across every cloud provider service.",
            img: ServiceCoverage,
            imgAlt: 'complete service for coverage',
            imgWidth: '55%'
        }
    ]
    const makeDiffItem = (diffID: string, isMobile?: boolean): JSX.Element => {
        const item = differenceList.filter( item => item.id === diffID )
        if(item) {
            const imgWidth = isMobile ? '100%' : item[0]?.imgWidth
            return <DifferenceItemSection img={item[0].img} imgAlt={item[0].imgAlt} imgWidth={imgWidth} paragraph={item[0].desc}/>
        }
        return <></>
    }

    const [activeDiff, setActiveDiff] = useState<string>('pre-over-det')
    const [diff, setDiff] = useState<any>(makeDiffItem('pre-over-det'))
    const [diffM, setDiffM] = useState<any>(makeDiffItem('pre-over-det', true))

    const setDiffItem = (diffID: string) => {
        setDiff(makeDiffItem(diffID))
        setDiffM(makeDiffItem(diffID, true))
        setActiveDiff(diffID)
    }

    const makeTitles = (): JSX.Element => {
        const titles = differenceList.map((item) => {
            return (
                <div key={item.id} className={'differences-titles-item' }>
                    <button className={'differences-titles-item-button '  + (activeDiff === item.id ? 'active-diff' : '')}
                            data-diff={item.id} onClick={(event) => {
                        setDiffItem(event.currentTarget.getAttribute('data-diff'))
                    }}
                    >
                        <span className={'differences-titles-item-button-text'}>
                            { item.title }
                        </span>
                    </button>
                </div>
            )
        })
        return <>{titles}</>
    }

    const makeTitlesMobile = (): JSX.Element => {
        const titles = differenceList.map((item) => {
            return (
                <li key={item.id} className={'list-item ' + (activeDiff === item.id ? 'active-diff' : '') }>
                    <button className={'button ' + (activeDiff === item.id ? 'active-diff' : '')}
                            data-diff={item.id} onClick={(event) => {
                        setDiffItem(event.currentTarget.getAttribute('data-diff'))
                    }}
                    >
                        <span className={'text ' + (activeDiff === item.id ? 'active-diff' : '')}>
                            { item.title }
                        </span>
                    </button>
                </li>
            )
        })
        return <>{titles}</>
    }

    return (
        <div className={'container-product-what-makes-us-different'}>
            <div className={'mobile-container'}>
                <div className={'container top'}>
                    <div className={'title'}>
                        <span className={'text heading h5'}>
                            What makes us different
                        </span>
                    </div>
                </div>
                <div className={'bottom'}>
                    <ul className={'titles-list'}>
                        { makeTitlesMobile() }
                    </ul>
                    <div className={'container differences'}>
                        { diffM }
                    </div>
                </div>
            </div>
            <div className={'container content'}>
                <div className={'top'}>
                    <div className={'title'}>
                        <span className={'text heading h4'}>
                            What makes us different
                        </span>
                    </div>
                </div>
                <div className={'bottom'}>
                    <div className={'differences-titles'}>
                        { makeTitles() }
                    </div>
                    <div className={'differences'}>
                        { diff }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductWhatMakesUsDifferentSection;
