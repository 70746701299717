import React from "react"
import { LineSpacer } from "@/components"

interface IProps {
    title?: string
    titleOnly?: boolean
    paras: string[]
    noSpace?: boolean
}

const Paragraph: React.FC<IProps> = ({title, titleOnly, paras, noSpace}: IProps) => {

    const ls = noSpace ? null : <LineSpacer/>
    const makePara = (): JSX.Element => {
        const paraList = paras.map((p, index) => {
            return (
                <div key={String(index)} className={'para'}>
                    <span className={'paragraph sm'}>{p}</span>
                    { ls }
                </div>
            )
        })
        return <>{ paraList }</>
    }

    return (
        <div className={'container-paragraph'}>
            {
                title !== undefined ?
                <div className={'title'}>
                    <span className={'paragraph sm bold'}>{title}</span>
                </div>
                : null
            }
            { !titleOnly ? makePara() : null }
        </div>
    )
}

export default Paragraph
