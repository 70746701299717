import React from "react"
import AWS from "@/assets/images/partners/aws.png";
import AZURE from "@/assets/images/partners/azure.png";
import GCP from "@/assets/images/partners/gcp.png";

const AboutUsOurPartnersSection: React.FC = () => {
  return (
    <div className={'container-about-us-our-partners'}>
      <div className={'container content'}>
        <div className={'top'}>
            <span className={'text heading h4'}>
                Our Partners
            </span>
        </div>
        <div className={'bottom'}>
          <div className={'partner-logo'}/>
            <div className={'partner-logo'}>
              <img src={AWS} alt={'aws'}/>
            </div>
          <div className={'partner-logo'}>
            <img src={AZURE} alt={'azure'}/>
          </div>
          <div className={'partner-logo'}>
            <img src={GCP} alt={'gcp'}/>
          </div>
          <div className={'partner-logo'}/>
        </div>
      </div>
    </div>
  )
}

export default AboutUsOurPartnersSection;
