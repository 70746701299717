import React from "react"
import PLaceholder from "@/assets/images/placeholder_fuschia.svg"
import { SectionImageFullsize } from "@/components"
import CloudEnablementFeature from "@/assets/images/full-size/cloud-enablement-feature.svg"

export const FEATURE_ANCHOR_ID_CLOUD_ENABLE = 'feature-cloud-enablement'

const CloudEnablement: React.FC = () => {
    return (
        <div id={FEATURE_ANCHOR_ID_CLOUD_ENABLE} className={'feature-content'}>
            <div className={'title'}>
                <span className={'text'}>
                    Same Preventative Policy, Every Tool.
                </span>
            </div>
            <div className={'paragraph-container'}>
                <span className={'paragraph'}>
                    Organisations spend significant time and resources implementing cloud controls for specific deployment 
                    tooling. As cloud adoption grows and complexity of use cases increases, teams seek alternate tooling that 
                    better suits their workload. Introducing new tools to the organisation requires a reinvestment in control 
                    implementation, and often leads to a trade-off between time, cost and security posture. It doesn't have 
                    to be this way.
                </span>
            </div>
            <div className={'image-container'}>
                <SectionImageFullsize
                    img={CloudEnablementFeature}
                    imgAlt={'Cloud Enablement'}
                />
            </div>            
            <div className={'paragraph-container'}>
                <span className={'paragraph'}>
                    Every cloud resource is provisioned and configured through an API request, regardless of what tool is used. 
                    Kivera understands and inspects every cloud API request, determining whether the requested configuration 
                    aligns to your organisation's policies. Written once, the same policy applies consistently across any tool 
                    allowing for complete flexibility in tooling. Applying preventative policies, fully customisable to your 
                    organisation's needs, Kivera accelerates cloud innovation by simplifying cloud security.
                </span>
            </div>
            {/* <div className={'link-container'}>
                <a className={'link'} href={'#'}>
                    See how this works in a use case
                </a>
            </div> */}
        </div>
    )
}

export default CloudEnablement
