import React from 'react';
import Helmet from 'react-helmet';
import FavIcon from "@/assets/images/favicon.png"

const SEO = () => {
    return  (
        <Helmet>
            <html lang="en"  />
            <meta charSet="UTF-8"/>
            <meta name="description"
                content="Kivera is the next generation of cloud compliance tooling providing deep visibility and protection across your cloud deployments."
            />
            <meta name="keywords"
            content="cloud, governance, security, aws, gcp, azure, cspm, casb, preventative, policy, rules" />
            <meta name="author" content="Kivera" />
            <meta name="apple-mobile-web-app-title" content="Kivera - Your cloud. Your rules." />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
            <title>Kivera - Your cloud. Your rules.</title>
            <link rel="shortcut icon"  type="image/png" href={FavIcon} sizes="32x32" />

            {/*Global site tag (gtag.js) - Google Analytics*/}
            <script async src={"https://www.googletagmanager.com/gtag/js?id=" + `${process.env.GOOGLE_TAG_ID}`} />
            <script>
                { `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${process.env.GOOGLE_TAG_ID}');
                ` }
            </script>

            <link href="https://cdnjs.cloudflare.com/ajax/libs/lity/2.4.1/lity.css" rel="stylesheet"></link>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/lity/2.4.1/lity.min.js"></script>
        </Helmet>
    )
}

export default SEO;