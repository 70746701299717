import React, { useEffect, useState } from "react"
import {
    CloudEnablement,
    DataLossPrevention,
    FEATURE_ANCHOR_ID_CLOUD_ENABLE,
    FEATURE_ANCHOR_ID_DLP,
    FEATURE_ANCHOR_ID_HYB_DEP,
    FEATURE_ANCHOR_ID_ID_AWARE, FEATURE_ANCHOR_ID_TAGGING, FEATURE_ANCHOR_ID_TBZ,
    HybridDeployment,
    IdentityAware,
    Tagging, TrailBlazer
} from "@/layout";
import { useLocation, navigate  } from "@reach/router"

const FeaturesSection: React.FC = () => {
    const FEATURE_TBZ = 'tbz'
    const FEATURE_DLP = 'dlp'
    const FEATURE_CLOUD_ENABLE = 'cloud-enable'
    const FEATURE_ID_AWARE = 'id-aware'
    const FEATURE_HYB_DEP = 'hyb-dep'
    const FEATURE_TAGGING = 'tagging'

    const featureList = [
        {
            id: FEATURE_TBZ,
            anchor: FEATURE_ANCHOR_ID_TBZ,
            desc: 'TrailBlazer'
        },
        {
            id: FEATURE_DLP,
            anchor: FEATURE_ANCHOR_ID_DLP,
            desc: 'Data Loss Prevention'
        },
        {
            id: FEATURE_CLOUD_ENABLE,
            anchor: FEATURE_ANCHOR_ID_CLOUD_ENABLE,
            desc: 'Cloud Enablement'
        },
        {
            id: FEATURE_ID_AWARE,
            anchor: FEATURE_ANCHOR_ID_ID_AWARE,
            desc: 'Identity Aware'
        },
        {
            id: FEATURE_HYB_DEP,
            anchor: FEATURE_ANCHOR_ID_HYB_DEP,
            desc: 'Hybrid Deployment'
        },
        {
            id: FEATURE_TAGGING,
            anchor: FEATURE_ANCHOR_ID_TAGGING,
            desc: 'Tagging'
        },
        // {
        //     id: 'pre-policies',
        //     desc: 'Preventative Policies'
        // },
        // {
        //     id: 'pol-as-code',
        //     desc: 'Policy as Code'
        // },
    ]

    const location = useLocation()
    const [feature, setFeature] = useState<any>(<TrailBlazer/>)
    const [activeFeature, setActiveFeature] = useState<string>(FEATURE_TBZ)

    const mapHashAnchorToFeature = (hash: string): string => {
        let featureID = ''
        const filteredFeature = featureList.filter( item => item.anchor === hash)
        if(filteredFeature?.[0]){
            featureID = filteredFeature[0].id
        }
        return featureID
    }

    const mapFeatureIDToHashAnchor = (featureID: string): string => {
        let hash = ''
        const filteredFeature = featureList.filter( item => item.id === featureID)
        if(filteredFeature?.[0]){
            hash = filteredFeature[0].anchor
        }
        return hash
    }

    useEffect(()=> {
        if (location.hash) {
            const featureAnchorID = location.hash.slice(1)
            const featureItem = mapHashAnchorToFeature(featureAnchorID)
            if(featureItem !== ''){
                setFeatureItem(featureItem)
                let elem = document.getElementById('features')
                if (elem) {
                    elem.scrollIntoView({behavior: "smooth"})
                }else {
                    console.log('NOT FOUND')
                }
            }
        }
    }, [location])


    const setFeatureItem = (featureID: string) => {
        setActiveFeature(featureID)
        switch (featureID){
            case FEATURE_CLOUD_ENABLE:
                setFeature(<CloudEnablement/>)
                break
            case FEATURE_DLP:
                setFeature(<DataLossPrevention/>)
                break
            case FEATURE_TBZ:
                setFeature(<TrailBlazer/>)
                break
            case FEATURE_ID_AWARE:
                setFeature(<IdentityAware/>)
                break
            case FEATURE_HYB_DEP:
                setFeature(<HybridDeployment/>)
                break
            case FEATURE_TAGGING:
                setFeature(<Tagging/>)
                break
            // case 'pol-as-code':
            //     setFeature(<PolicyAsCode/>)
            //     break
            // case 'pre-policies':
            //     setFeature(<PreventativePolicies/>)
            //     break
            default:
                setFeature(undefined)
        }
    }

    const makeListStyle = (id: string, index: number): string => {
        let style = ''
        if(id === activeFeature){
            style += ' item-active '
        }else{
            if(index === 0){
                style += ' border-top '
            }
        }
        return style
    }

    const navigateToFeature = (featureID: string) => {
        const hashAnchor = mapFeatureIDToHashAnchor(featureID)
        if(hashAnchor !== ''){
            navigate('#' + hashAnchor, { replace: true}).then().catch(console.error)
        }
    }

    const makeFeatureList = () => {
        return featureList.map((item, index) => {
          return (
              <li key={item.id} className={'container-features-content-right-container-list-item ' + makeListStyle(item.id, index)}>
                  <div className={'container-features-content-right-container-list-item-container'}>
                      <button className={'container-features-content-right-container-list-item-button'}
                              data-feature={item.id} onClick={(event) => {
                          navigateToFeature(event.currentTarget.getAttribute('data-feature'))
                      }}
                      >
                          <div className={'container-features-content-right-container-list-item-button-text ' + (item.id === activeFeature ? 'item-active' :'') }>
                              { item.desc }
                          </div>
                      </button>
                  </div>
              </li>
          )
        })
    }

    const makeFeatureListMobile = () => {
        return featureList.map((item, index) => {
            return (
                <li key={item.id} className={'list-item ' + (item.id === activeFeature ? 'item-active' :'')}>
                        <button className={'button'}
                                data-feature={item.id} onClick={(event) => {
                            navigateToFeature(event.currentTarget.getAttribute('data-feature'))
                        }}
                        >
                            <div className={'text paragraph md secondary' }>
                                { item.desc }
                            </div>
                        </button>
                </li>
            )
        })
    }

    return (
        <div id="features" className={'container-features'}>
            <div className={'mobile-container'}>
                <div className={'container title'}>
                    <span className={'text heading h5'}>
                        Features
                    </span>
                </div>
                <div className={'links'}>
                    <ul className={'list '}>
                        { makeFeatureListMobile() }
                    </ul>
                </div>
                <div className={'container content'}>
                    { feature }
                </div>
            </div>
            <div className={'container container-features-content'}>
                <div className={'container-features-content-left'}>
                    <div className={'container-features-content-left-title'}>
                        <span className={'container-features-content-left-title-text heading h4'}>
                            Features
                        </span>
                    </div>
                    <div className={'container-features-content-left-content'}>
                        <div className={'container-features-content-left-content-inner'}>
                            { feature }
                        </div>
                    </div>
                </div>
                <div className={'container-features-content-right'}>
                    <div className={'container-features-content-right-container'}>
                        <ul className={'container-features-content-right-container-list'}>
                            { makeFeatureList() }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeaturesSection
