import React from 'react';

interface IProps {
  formURL : string
  formID: string
  className: string
  iframeClassName: string
}

const PipedriveForm: React.FC<IProps> = ({ formID, formURL, className, iframeClassName}: IProps) =>  {
  // data-pd-webforms={`${process.env.RD_PIPEDRIVE_FORM_URL}`}
  return (
            <div
                className={className}
                data-pd-webforms={formURL}
                id={formID} //could be a random string number combo
            >
                <iframe
                  src={formURL + '?embeded=1&uuid=' + formID}
                  name={'kivera-' + formID}
                  scrolling="no"
                  className={iframeClassName}
                />
            </div>
        )
}

export default PipedriveForm;
