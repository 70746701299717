import React from "react"
import { NavBar, NavBarMobile } from "@/layout"

const ReportHeroSection: React.FC = () => {
    return (
        <div className={'container-report-hero'}>
            <NavBarMobile/>
            <div className={'container'}>
                <NavBar/>
            </div>
        </div>
    )
}

export default ReportHeroSection;
