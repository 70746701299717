import React from "react"
import { LineSpacer, Paragraph } from "@/components"

const PrivacyThirdpartySection: React.FC = () => {
    return (
        <div className={'container-thirdparty-policy'}>
            <div className={'container'}>
                <div className={'section-thirdparty-policy'}>

                    <Paragraph
                        title={'Third Party Collection of Information'}
                        paras={[
                            'We have provided you with information above about the types of third party information storage or gathering techniques on our website. This privacy policy does not apply to applications (apps), Kivera technologies or sites that are owned and/or operated by other parties (such as app developers, third party advertisers or third party ad servers), even if they use our technology to store or collect data. This includes third parties that embed our technology in their apps or advertisements to facilitate download tracking or advertisement serving. We do, however, work with third parties to ensure that you are provided with the necessary information and are provided with the opportunity to hmake your choice. The relevant third party’s terms of use, privacy policy, permissions, notices and choices should be reviewed by yourself regarding their collection, storage and sharing practices since they may be different from ours.',
                        ]}/>

                    <Paragraph
                        title={'Your Consent and Your Right to Refuse the Use of Information Collection Technologies'}
                        paras={[
                        'We obtain your consent to our information collection technologies by providing you with transparent information in our privacy policy and providing you with the opportunity to make your choice. You have the right to object to the use of information collection technologies. Regular cookies may generally be disabled or removed by tools that are available as part of most commercial browsers, and in some but not all instances can be blocked in the future by selecting certain settings. Each browser you use will need to be set separately and different browsers offer different functionality and options in this regard. Also, these tools may not be effective with regard to Flash cookies or HTML5 cookies. For information on disabling Flash cookies go to Adobe‘s website. Please be aware that if you disable or remove these technologies some parts of our website will not work and that when you revisit our website your ability to limit cookies is subject to your browser settings and limitations.'
                    ]}/>

                    <Paragraph
                        title={'Right to Complaint or Review by Commissioner'}
                        paras={[
                            'You may have the right to file a complaint or request a review of decisions made regarding your personal information with the relevant Privacy Commissioner or other public body within your jurisdiction. If you require further information or have any questions or concerns, please contact our Privacy Officer at the contact information contained below.”'
                        ]}
                    />

                    <Paragraph
                        title={'Overseas Entities'}
                        paras={[
                            'Kivera operates in jurisdictions in various countries, including Canada and Australia. Please take notice that it is possible that personal information retained by Kivera may be transferred and/or accessible to our entities overseas. Our overseas entities remain compliant with the provisions of this Privacy Policy and applicable law.'
                        ]}
                    />

                    <Paragraph
                        title={'Anonymity and Pseudonymity'}
                        paras={[
                            'Individuals have the right to interact with Kivera anonymously and pseudonymously. Please note that where you exercise such right to anonymity or pseudonymity, your full access and appreciation of Kivera’s services may be limited, where impracticable to do so. Some services may also require identification under applicable laws.'
                        ]}
                    />

                    <Paragraph
                        title={'Communications'}
                        paras={[
                            'With your consent, we may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.'
                        ]}
                    />

                    <Paragraph
                        title={'Compliance With Laws'}
                        paras={[
                            'As set out above, and for clarity, we will disclose your Personal Information where required to do so by law or subpoena or if we believe that such action is necessary to comply with the law and the reasonable requests of law enforcement or to protect the security or integrity of our Service.'
                        ]}
                    />

                    <Paragraph
                        title={'Security'}
                        paras={[
                            'The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.'
                        ]}
                    />

                    <Paragraph
                        title={'Links To Other Sites'}
                        paras={[
                            'Our website may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.',
                            'We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites or services.'
                        ]}
                    />

                    <Paragraph
                        title={'Changes To This Privacy Policy'}
                        paras={[
                            'We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.',
                            'You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.',
                            'If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.'
                        ]}
                    />

                    <Paragraph
                        title={'Questions about this Privacy Policy'}
                        paras={['']}
                        titleOnly={true}
                    />

                    <span className={'paragraph small'}>
                        If you have a question about this Privacy Policy or about Kivera’s handling of your information,
                        you can send an email to the privacy officer for Kivera at {' '}
                        <a className={'color-primary'} href={'mailto:privacyofficer@kivera.io'}>
                        privacyofficer@kivera.io</a>.
                        We are unable to provide you with any information or correct any inaccuracies, until we are able
                        to verify your identity and to provide other details to help us to respond to your request.
                        We will contact you within 30 days of your request. You may also mail a request to the attention of
                        “Privacy Officer” at 55 York Street, 13th Floor, Toronto, Ontario M5J 1R7.
                    </span>

                    <LineSpacer/>

                </div>
            </div>
        </div>
    )
}

export default PrivacyThirdpartySection;
