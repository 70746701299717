import React from "react"
import { PricingIncludedSubSection } from "./pricing-included-subsection"
const PricingAboveFooterSection: React.FC = () => {

    const newsArticles = [
        {
            title: 'Capital One Data Breach Affects 106 Million Customers; Hacker Arrested',
            link: 'https://thehackernews.com/2019/07/capital-one-data-breach.html'
        },
        {
            title: 'The latest data breach involves the voting records of 93.4 million Mexican citizens',
            link: 'https://www.digitaltrends.com/computing/mexico-voting-breach/'
        },
        {
            title: 'AWS S3 Buckets Exposed Millions of Facebook Records',
            link: 'https://www.securityweek.com/aws-s3-buckets-exposed-millions-facebook-records'
        },
        {
            title: 'Open database leaked 179GB in customer, US government, and military records',
            link: 'https://www.zdnet.com/article/autoclerk-database-leaked-customer-government-and-military-personal-records/'
        },
        {
            title: 'Over 14 Million Verizon Customers\' Data Exposed On Unprotected AWS Server',
            link: 'https://thehackernews.com/2017/07/over-14-million-verizon-customers-data.html'
        },
        {
            title: 'Indian Brokerage Firm Upstox Suffers Data Breach Leaking 2.5 Millions Users\' Data',
            link: 'https://thehackernews.com/2021/04/indian-brokerage-firm-upstox-suffers.html'
        },
        {
            title: 'Virgin Media breach: unprotected database was stored in the cloud, telco confirms',
            link: 'https://tech.newstatesman.com/security/virgin-media-breach-cloud'
        },
        {
            title: '2.4 Million Dow Jones High-Risk Watchlist Clients Exposed',
            link: 'https://finance.yahoo.com/news/2-4-million-dow-jones-030716231.html'
        },
        {
            title: 'Over 54,000 scanned NSW driver\'s licences found in open cloud storage',
            link: 'https://www.itnews.com.au/news/over-54000-scanned-nsw-drivers-licences-found-in-open-cloud-storage-552544'
        },
        {
            title: 'Google Cloud Buckets Exposed in Rampant Misconfiguration',
            link: 'https://threatpost.com/google-cloud-buckets-exposed-misconfiguration/159429/'
        }
    ]

    const makeNewsList = (): JSX.Element => {
        const list =  newsArticles.map((article, index) => {
            return (
                <div key={String(index)} className={'title-container'}>
                    <span className={'title'}>
                        &#x25A0; {' '}
                        <a href={article.link}
                           target={'_blank'}
                           className={'link'}
                        >
                            { article.title }
                        </a>
                    </span>
                </div>
            )
        })
        return <>{list}</>
    }


    return (
        <div className={'container-pricing-news'}>
            <div id="misconfigurations-in-the-news" className={'container content'}>
                <div className={'top'}>
                    <span className={'text heading h4'}>
                        Cloud Mis&shy;configurations in the News
                    </span>
                </div>
                <div className={'bottom'}>
                    <div className={'container-pricing-news-sub-section'}>
                        <div className={'news'}>
                            { makeNewsList() }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PricingAboveFooterSection