import React from "react"
import { Footer } from "@/components"

interface IProps {
    children: any
}

const DefaultLayout: React.FC<IProps> = (props: IProps) => {
    return (
        <div className={'layout-default'}>
            <div className={'container-header'}>
                {props.children}
            </div>
            <div className={'container-footer'}>
                <Footer/>
            </div>
        </div>
    )
}

export default DefaultLayout;
