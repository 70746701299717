import React from "react"
// import { Document } from "react-pdf";
// import { Document } from "react-pdf/dist/esm/entry.webpack";


interface IProps {
    pdfURL?: string
    pdfAlternateText?: string
}

const SectionPDF: React.FC<IProps> = ({pdfURL, pdfAlternateText}: IProps) => {
    return (
       <div className={'section-pdf-container'}>
         <div className={'section-pdf'}>
           <object
             data={pdfURL}
             type="application/pdf"
             width="100%"
             height="678"
           >
             <iframe
               src={pdfURL}
               width="100%"
               height="678"
             >
               <p>This browser does not support PDF!</p>
             </iframe>
           </object>
         </div>
         <div className={'section-pdf-mobile'}>
           <span className={'pdf-link'}>
             <a href={pdfURL} target={"_blank"} className={'thick-link'}>
               { pdfAlternateText }
             </a>
           </span>
         </div>
       </div>
    )
}

export default SectionPDF
