import React from "react"
import { CarouselImage, LogoCarousel } from "@/components"
import Bamboo from "@/assets/icons/tools/bamboo.svg"
import Terraform from "@/assets/icons/tools/terraform.svg"
import AWSCodeBuild from "@/assets/icons/tools/aws-codebuild.svg"
import Gitlab from "@/assets/icons/tools/gitlab.svg"
import TeamCity from "@/assets/icons/tools/teamcity.svg"
import Waypoint from "@/assets/icons/tools/waypoint.svg"
import Codefresh from "@/assets/icons/tools/codefresh.svg"
import Ansible from "@/assets/icons/tools/ansible.svg"
import Github from "@/assets/icons/tools/github.svg"
import BitBucket from "@/assets/icons/tools/bitbucket.svg"
import Jenkins from "@/assets/icons/tools/jenkins.svg"
import CircleCI from "@/assets/icons/tools/circleci.svg"
import GoCD from "@/assets/icons/tools/gocd.svg"
import TravisCI from "@/assets/icons/tools/travis-ci.svg"
import Openshift from "@/assets/icons/tools/openshift.svg"
import Chef from "@/assets/icons/tools/chef.svg"
import Rancher from "@/assets/icons/tools/rancher.svg"
import Octopus from "@/assets/icons/tools/octopus.svg"
import Cloudbees from "@/assets/icons/tools/cloudbees.svg"
import Azuredevops from "@/assets/icons/tools/azuredevops.svg"
import Appveyor from "@/assets/icons/tools/appveyor.svg"
import Codeship from "@/assets/icons/tools/codeship.svg"
import GoogleCloudBuild from "@/assets/icons/tools/googlecb.svg"
import Buildkite from "@/assets/icons/tools/buildkite.svg"
import JFrog from "@/assets/icons/tools/jfrog.svg"
import CLI from "@/assets/icons/tools/cli.svg"
import { hasWindow } from "@/lib"

const ToolsCarouselSection: React.FC = () => {

    const isWindow = hasWindow()
    if(!isWindow) {
        return <></>
    }

    const tools: CarouselImage[] = [
        { imgSrc: Bamboo, imgAlt: 'bamboo'},
        { imgSrc: Terraform, imgAlt: 'terraform'},
        { imgSrc: AWSCodeBuild, imgAlt: 'aws-codebuild'},
        { imgSrc: Gitlab, imgAlt: 'gitlab'},
        { imgSrc: TeamCity, imgAlt: 'teamcity'},
        { imgSrc: Waypoint, imgAlt: 'waypoint'},
        { imgSrc: Codefresh, imgAlt: 'codefresh'},
        { imgSrc: Ansible, imgAlt: 'ansible'},
        { imgSrc: Github, imgAlt: 'github'},
        { imgSrc: BitBucket, imgAlt: 'bitbucket'},
        { imgSrc: Jenkins, imgAlt: 'jenkins'},
        { imgSrc: CircleCI, imgAlt: 'circle-ci'},
        { imgSrc: GoCD, imgAlt: 'gocd'},
        { imgSrc: TravisCI, imgAlt: 'travis-ci'},
        { imgSrc: Openshift, imgAlt: 'openshift'},
        { imgSrc: Chef, imgAlt: 'chef'},
        { imgSrc: Rancher, imgAlt: 'rancher'},
        { imgSrc: Octopus, imgAlt: 'octopus'},
        { imgSrc: Cloudbees, imgAlt: 'cloudbees'},
        { imgSrc: Azuredevops, imgAlt: 'azure devops'},
        { imgSrc: Appveyor, imgAlt: 'appveyor'},
        { imgSrc: Codeship, imgAlt: 'codeship'},
        { imgSrc: GoogleCloudBuild, imgAlt: 'google cloud build'},
        { imgSrc: Buildkite, imgAlt: 'buildkite'},
        { imgSrc: JFrog, imgAlt: 'jfrog'},
        { imgSrc: CLI, imgAlt: 'cli'},
    ]

    return (
        <div className={'container-tools-carousel-wrapper'}>
            <div className={'container container-tools-carousel'}>
                <LogoCarousel carouselImages={tools}
                              ciContainerClassName={'container-tools-carousel-content'}
                              ciClassName={'container-tools-carousel-content-image'}/>
            </div>
        </div>
    )
}

export default ToolsCarouselSection;
