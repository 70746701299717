import React from "react"
import MisconfigurationOccured from "@/assets/images/full-size/misconfiguration-occured.svg"
import AfterMisconfigurationOccured from "@/assets/images/full-size/after-misconfiguration-occurred.svg"
import BeforeMisconfigurationOccured from "@/assets/images/full-size/before-misconfiguration-occurred.svg"
import { LineSpacer, SectionImageFullsize, SectionQuote, SectionSubheadingParagraph, UnorderedList } from "@/components"

const UsecaseSecuringCloudNative: React.FC = () => {

    const list = [
        'Preventative policies that stop misconfigurations being deployed',
        'Policies apply across any tool developers choose',
        'Granular and fully customisable policies',
        'Re-usability of controls across every workload',
        'Full coverage for every cloud service across AWS, Google Cloud and Microsoft Azure',
        'Ability to apply controls based on workload risk profile',
        'Single control plane view of cloud governance, risk and compliance'
    ]


    return (
        <div className={'usecase-item'}>
            <div className={'usecase-item-section'}>
                <div className={'container'}>
                    <div className={'usecase-item-subsection'}>
                        <div className={'subsection-heading'}>
                            <span className={'heading h4'}>
                                Securing Cloud Native Consumption for Enterprises
                            </span>
                        </div>
                    </div>
                    <div className={'usecase-item-subsection'}>
                        <SectionSubheadingParagraph
                            paraClassName={'sub-para'}
                            subheading={'Customer Challenge'}
                            paragraph={
                                <div className={'text'}>
                                    <span>
                                        Cloud native consumption provides developers the flexibility to move fast and innovate in cloud
                                        environments. However, with great power comes great responsibility, and securing cloud native
                                        deployments is a complex challenge. Relaxing guardrails to support native deployments increases the risk of misconfigured
                                        resources leading to potential outages, data exfiltration and compliance breaches.
                                    </span>
                                    <LineSpacer />
                                    <span >
                                        <b>Kivera secures cloud native deployments ensuring your teams can innovate and deliver with
                                            velocity without compromising secure outcomes.</b>
                                    </span>
                                </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div className={'usecase-item-section-no-padding'}>
                <div className={'usecase-item-subsection'}>
                    <div className={'container'}>
                        <SectionImageFullsize
                            img={MisconfigurationOccured}
                            imgAlt={'misconfiguration occured'}
                        />
                    </div>
                </div>
            </div>
            <div className={'usecase-item-section'}>
                <div className={'container'}>
                    <div className={'usecase-item-subsection'}>
                        <SectionSubheadingParagraph
                            paraClassName={'sub-para'}
                            subheading={'Codified Controls for Secure Consumption'}
                            paragraph={
                                <div className={'text'}>
                                    <span>
                                        In enterprises, there are often two approaches to cloud consumption.
                                        The first approach codifies security, risk, compliance and operational controls into a centralised CI/CD mechanism.
                                        A threat modelling or service attestation process of identifying and implementing controls is undertaken
                                        for each cloud service that teams want to consume. This approach is a proven method to drive consumption
                                        at scale within enterprises, providing strong security outcomes and decreasing the risk of misconfiguration.
                                    </span>
                                    <LineSpacer />
                                    <span >
                                        However, this pattern is most effective for ‘replatforming’ and more complex workloads
                                        do not fit within the framework that is available. Additionally, over time, cloud tooling and
                                        methods to deploy cloud infrastructure innovate faster than the enterprise CI/CD mechanism, with
                                        developers seeking new tools and consumption patterns to have freedom to innovate within their
                                        cloud environments. While the guardrails in this approach provide strong security outcomes, they
                                        also apply some restrictions to developer flexibility in cloud environments.
                                    </span>
                                </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div className={'usecase-item-section'}>
                <div className={'container'}>
                    <div className={'usecase-item-subsection'}>
                        <SectionSubheadingParagraph
                            paraClassName={'sub-para'}
                            subheading={'Detective Scanning for Cloud Native'}
                            paragraph={
                                <div className={'text'}>
                                    <span>
                                        The second approach is closer to cloud native, providing developer teams a broader set
                                        of deployment privileges, who in turn take on greater ownership of security, risk,
                                        compliance and operational controls. This allows developers to choose the tools they
                                        prefer when managing their cloud deployments and provides greater access to each
                                        cloud service promoting innovation and speed. From a security and governance perspective
                                        however, workload controls become disparate, bespoke to teams, and the relaxed guardrails
                                        increase the risk of resource misconfiguration. Application developer teams now
                                        need to be experts in infrastructure configuration, cyber security, risk and
                                        regulatory compliance.
                                    </span>
                                    <LineSpacer />
                                    <span>
                                        To manage the increase in risk, enterprises look to provide guardrails and
                                        governance through Cloud Security Posture Management (CSPM) tooling that scans
                                        logs and gathers post-deployment data to detect resource misconfigurations.
                                        This approach allows risk, compliance and security teams to maintain oversight
                                        on native deployment using ‘detective’ controls. For enterprises, particularly
                                        regulated enterprises, notification of a misconfiguration hours after it has occurred
                                        is not an effective control. A misconfiguration can be exploited within seconds
                                        of deployment, which could result in sensitive data being exfiltrated amongst other
                                        damaging outcomes. Further, limited service coverage and granularity of policies
                                        within CSPMs constrain the effectiveness of this approach meaning it can’t be
                                        leveraged as the primary control mechanism for most regulated enterprises.
                                    </span>
                                    <LineSpacer />
                                    <span >
                                        Compounding the complexity of any consumption method is the exponential growth
                                        of cloud services and features, the expansion of cloud toolsets for developers
                                        and continual regulatory changes making secure consumption increasingly difficult,
                                        especially at enterprise scale.
                                    </span>
                                </div>
                            }
                        />
                    </div>
                </div>
            </div>
            {/* <div className={'usecase-item-section'}>
                <div className={'usecase-item-subsection'}>
                    <div className={'container'}>
                        <SectionQuote
                           quote={'The cloud is a datacentre at your fingertips, but you wouldn’t give one developer the keys to your entire datacentre and say “have at it!”.'}
                           quotedBy={'Vernon Jefferson, CTO Kivera'}
                        />
                    </div>
                </div>
            </div> */}
            <div className={'usecase-item-section-no-padding'}>
                <div className={'usecase-item-subsection'}>
                    <div className={'container'}>
                        <SectionImageFullsize
                            img={AfterMisconfigurationOccured}
                            imgAlt={'after misconfiguration occured'}
                        />
                    </div>
                </div>
            </div>
            <div className={'usecase-item-section'}>
                <div className={'container'}>
                    <div className={'usecase-item-subsection'}>
                        <SectionSubheadingParagraph
                            paraClassName={'sub-para'}
                            subheading={'Cloud Native Without Compromise'}
                            paragraph={
                                <div className={'text'}>
                                    <span>
                                        Understanding both methods of consumption are valid with differing benefits and constraints,
                                        what would an ideal consumption pattern look like for enterprise cloud consumption?
                                    </span>
                                    <span>
                                        <UnorderedList list={list} />
                                    </span>
                                    <span >
                                        <b>There’s only one tool that provides all of these outcomes, Kivera.</b>
                                    </span>
                                </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div className={'usecase-item-section-no-padding'}>
                <div className={'usecase-item-subsection'}>
                    <div className={'container'}>
                        <SectionImageFullsize
                            img={BeforeMisconfigurationOccured}
                            imgAlt={'before misconfiguration occured'}
                        />
                    </div>
                </div>
            </div>
            <div className={'usecase-item-section'}>
                <div className={'container'}>
                    <div className={'usecase-item-subsection'}>
                        <SectionSubheadingParagraph
                            paraClassName={'sub-para'}
                            subheading={''}
                            paragraph={
                                <div className={'text'}>
                                    <span>
                                        Kivera allows for fully customisable and deeply granular policies to govern every
                                        cloud deployment. The enterprise's security, risk, compliance and operational controls
                                        are developed as Kivera Policies which are written once but apply consistently across
                                        every tool. Policies can be grouped into Compliance Profiles aligned to regulators,
                                        industry frameworks, internal standards or a combination of any.
                                    </span>
                                    <LineSpacer />
                                    <span >
                                        Each workload is assigned one or more compliance profiles ensuring the right controls
                                        are applied to the workload commensurate with risk profile. Risk, security and compliance
                                        teams maintain oversight without restricting developers’ freedom to innovate and delivery
                                        velocity. As new cloud tools and cloud services become available, Kivera maintains currency
                                        ensuring teams can access the latest innovations with zero lag. <b>Your cloud. Your rules.</b>
                                    </span>
                                </div>
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsecaseSecuringCloudNative
