import React, { useEffect } from "react"
import PipedriveForm from "@/components/pipedrive-form"
import { useLocation } from "@reach/router"

const CTASection: React.FC = () => {
    const location = useLocation()

    useEffect(()=> {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({behavior: "smooth"})
            }
        } else {
            window.scrollTo({top:0,left:0, behavior: "smooth"})
        }
    }, [location])

    return (
        <section id="request" className={'section-container-cta'}>
            <div className={'cta-content'}>
                <div className={'request'}>
                    <h1 className={'text heading h4'}>Request Demo</h1>
                </div>
                <PipedriveForm
                  key={'42f10037fe80'}
                  formID={'42f10037fe80'}
                  formURL={`${process.env.RD_PIPEDRIVE_FORM_URL}`}
                  className={'pipedriveWebForms'}
                  iframeClassName={'pipedriveWebForms-iframe'}
                />
            </div>
        </section>
    )
}

export default CTASection;
