import React from "react"
import SimplifiedCloudGovernance from "@/assets/images/simplified-cloud-governance.svg"
import { SectionImageText } from "@/components"
import { COLOR_MEDIUM_GREY } from "@/styles/css-in-js"

const IdentityAwareSection: React.FC = () => {
    return (
        <div className={'container-identity'} style={{backgroundColor: COLOR_MEDIUM_GREY}}>
            <SectionImageText
                className={'identity-aware'}
                img={SimplifiedCloudGovernance}
                imgAlt={'identity-aware-image'}
                imgStyle={{ width: '80%'}}
                title={<>
                    <span className={'underline-expanding'}>Simplified</span> Cloud Governance, Risk and Regulatory Compliance
                </>}
                paragraph={<>
                    Kivera Compliance Profiles are fully customisable groupings of policies applied to workloads based
                    on your {' '}
                    <a href={'/product#feature-tagging'} className={'thick-link'}>
                        internal risk
                    </a>, data sensitivity or external regulatory requirements. Compliance Profiles
                    ensure controls are enforced commensurate with the inherent risk of each workload, supporting your
                    compliance objectives without restricting delivery velocity.
                </>}
            />
        </div>
    )
}

export default IdentityAwareSection;
