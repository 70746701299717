import React from "react"
import { PricingIncludedSubSection } from "./pricing-included-subsection"
import OutOfBoxPolicies from "@/assets/images/out-of-box-policies-dark-bg.svg"
import SSO from "@/assets/images/sso-dark-bg.svg"
import UnlimitedUsers from "@/assets/images/unlimited-users-dark-bg.svg"
import UnlimitedCustomPolicies from "@/assets/images/custom-policies-dark-bg.svg"

const PricingIncludedSection: React.FC = () => {
    return (
        <div className={'container-pricing-included'}>
            <div className={'container content'}>
                <div className={'top'}>
                    <span className={'text heading h4'}>
                       Included in every Kivera deployment
                    </span>
                </div>
                <div className={'bottom'}>
                    <PricingIncludedSubSection
                        img={OutOfBoxPolicies}
                        imgAlt={'Out of the Box Policies'}
                        title={'Out of the Box Policies'}
                        paragraph={'500+ out of the box policies across AWS, Google Cloud and Microsoft Azure.'}
                    />
                    <PricingIncludedSubSection
                        img={UnlimitedCustomPolicies}
                        imgAlt={'Unlimited Custom Policies'}
                        title={'Unlimited Custom Policies'}
                        paragraph={'Create as many custom policies as you like'}
                    />
                    <PricingIncludedSubSection
                        img={UnlimitedUsers}
                        imgAlt={'Unlimited Users'}
                        title={'Unlimited Users'}
                        paragraph={'Increase the visibility of cloud compliance across your organisation'}
                    />

                    <PricingIncludedSubSection
                        img={SSO}
                        imgAlt={'SSO'}
                        title={'SSO'}
                        paragraph={'Single Sign-On support for most identity providers'}
                    />
                </div>
            </div>
        </div>
    )
}

export default PricingIncludedSection;