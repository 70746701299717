import React from "react"
import PoliceImg from "@/assets/images/police.svg"
import { SectionImageText } from "@/components"
import { COLOR_LIGHT_GREY } from "@/styles/css-in-js"

const PreventativePolicySection: React.FC = () => {
    return (
        <div className={'container-preventative'} style={{backgroundColor: COLOR_LIGHT_GREY}}>
            <SectionImageText
                className={'preventative-policy'}
                img={PoliceImg}
                imgAlt={'preventative-policy-image'}
                imgClassName={'preventative-policy-image'}
                title={<>
                    Preventative Control Enforcement Across <span className={'underline-expanding'}>Every</span> Cloud Resource
                </>}
                paragraph={<>
                    Kivera enforces preventative policies across your cloud resources stopping misconfigurations turning
                    into attack vectors and data breaches. With support for every cloud provider service, each policy is
                    written in an open source policy language (
                    <a href={'https://www.openpolicyagent.org/docs/latest/policy-language/'}
                       target={'_blank'} className={'thick-link'}>
                        Rego
                    </a>
                    ) providing full flexibility from simple validation to granular
                    conditional policies. <b>Your cloud. Your rules.</b>
                </>}
            />
        </div>
    )
}

export default PreventativePolicySection;
