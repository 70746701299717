import React from "react"
import {
    SectionPDF,
    SectionSubheadingParagraph
} from "@/components";

const ReportAssessment: React.FC = () => {
    return (
      <div className={"section-report-case"}>
        <div className={'report-item'}>
            <div className={'report-item-section'}>
                <div className={'container'}>
                    <div className={'report-item-subsection'}>
                        <div className={'subsection-heading'}>
                            <span className={'heading h4'}>
                                About the Assessment
                            </span>
                        </div>
                    </div>
                    <div className={'report-item-subsection'}>
                        <SectionSubheadingParagraph
                          paraClassName={'sub-para'}
                          subheading={''}
                          paragraph={
                              <div className={'text'}>
                                    <span>
                                        Kivera's Cloud Security Assessment will analyse your cloud for up to 30 days to identify risks and misconfigurations.
                                        Using our catalogue of controls, Kivera will provide you with a detailed security report containing insights, analysis
                                        and clear recommendations. To find out more, fill out the form below – <b>no fee, no obligation</b>.
                                    </span>
                              </div>
                          }
                        />
                    </div>
                </div>
            </div>
            <div className={'report-item-section'}>
                <div className={'report-item-subsection'}>
                    <div className={'container'}>
                        <SectionPDF
                          pdfURL={"https://kivera.io/static/Kivera%20-%20Cloud%20Security%20Assessment%20[PUBLIC].pdf"}
                          pdfAlternateText={"Download Sample Assessment"}
                        />
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
}

export default ReportAssessment
