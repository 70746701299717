import React, { useState } from "react"
import {
    NavBar,
    UsecaseEnableNewCloud,
    UsecaseProtectSupportCloud, UsecaseSecuringCloudNative,
} from "@/layout"
import ManOnComputer from "@/assets/images/man-on-computer.svg"
import ManTouchingCloud from "@/assets/images/man-touching-cloud.svg"
import MascotRobots from "@/assets/images/mascot-robots.svg"

const UsecasesHeroCaseSection: React.FC = () => {
    type UseCase = {
        id: string
        title: string
        img: string
        imgAlt: string
    }

    const secureCloudUC: UseCase = {
        id: 'securing-cloud-native',
        title: 'Securing Cloud Native Consumption for Enterprises',
        img: ManTouchingCloud,
        imgAlt: 'Securing Cloud Native Consumption for Enterprises'
    }
    const protectUC: UseCase = {
        id: 'protect-support',
        title: 'Protect and Support Cloud Native Consumption',
        img: ManOnComputer,
        imgAlt: 'Protect and Support Cloud Native Consumption'
    }
    const enableUC: UseCase = {
        id: 'enable-new-cloud',
        title: 'Enable New Cloud Services In Your Organisation FAST',
        img: MascotRobots,
        imgAlt: 'Enable New Cloud Services In Your Organisation FAST'
    }

    const useCasesList: UseCase[] = [secureCloudUC]
    const [useCase, setUseCase] = useState<any>(<UsecaseSecuringCloudNative/>)
    const [activeUseCase, setActiveUseCase] = useState<UseCase>(secureCloudUC)


    const setUseCaseItem = (useCaseID: string) => {
        switch (useCaseID){
            case 'securing-cloud-native':
                setActiveUseCase(secureCloudUC)
                setUseCase(<UsecaseSecuringCloudNative/>)
                break
            case 'enable-new-cloud':
                setActiveUseCase(enableUC)
                setUseCase(<UsecaseEnableNewCloud/>)
                break
            case 'protect-support':
                setActiveUseCase(protectUC)
                setUseCase(<UsecaseProtectSupportCloud/>)
                break
            default:
                setUseCase(undefined)
        }
    }

    const makeUseCasesTitles = (): JSX.Element => {
        const titles = useCasesList.map((useCase) => {
            return (
                <div key={useCase.id} className={'usecases-titles-case'}>
                    <a className={'link'} onClick={() => setUseCaseItem(useCase.id)}>
                        <span className={'text ' + String(useCase.id === activeUseCase.id ? 'active-case' : '')}>{useCase.title}</span>
                    </a>
                </div>
            )
        })
        return <>{titles}</>
    }

    return (
        <>
            {/*<div className={'container-usecases-navbar-hero'}>*/}
            {/*    <div className={'container usecases-navbar'}>*/}
            {/*        <NavBar/>*/}
            {/*    </div>*/}
            {/*    <div className={'container usecases-hero'}>*/}
            {/*        <div className={'left'}>*/}
            {/*            <div className={'title'}>*/}
            {/*                <span className={'text'}>Use Cases</span>*/}
            {/*            </div>*/}
            {/*            <div className={'usecases-titles'}>*/}
            {/*                { makeUseCasesTitles() }*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className={'right'}>*/}
            {/*            <div className={'image-container'}>*/}
            {/*                <img className={'image'} src={activeUseCase.img} alt={activeUseCase.imgAlt} />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={'section-usecases-case'}>
                { useCase }
            </div>
        </>
    )
}

export default UsecasesHeroCaseSection;
