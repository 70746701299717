import React from "react"
import CloudExfiltration from "@/assets/images/cloud-exfiltration.svg"
import { SectionImageText } from "@/components"
import { COLOR_WHITE } from "@/styles/css-in-js"

const SupportCloudSection: React.FC = () => {
    return (
        <div className={'container-support'} style={{backgroundColor: COLOR_WHITE}}>
            <SectionImageText
                className={'support-cloud'}
                img={CloudExfiltration}
                imgAlt={'cloud-exfiltration'}
                imgClassName={'cloud-exfiltration-image'}
                title={<>
                    Stop Cloud Data Exfiltration <span className={'underline-expanding'}>Before</span> It Happens
                </>}
                paragraph={<>
                    With deep visibility into every cloud request, Kivera applies strict data transfer policies ensuring
                    your data stays within your organisation's virtual boundaries. Each data transfer is {' '}
                    <a href={'/product#feature-identity-aware'} className={'thick-link'}>
                        authenticated and authorised
                    </a> based on source identity and destination resource - whether you're transferring data
                    within cloud, between clouds or from on-premise to cloud.
                </>}
                reverse={true}
            />
        </div>
    )
}

export default SupportCloudSection;
