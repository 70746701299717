import React from "react"

const ReportHeaderSection: React.FC = () => {
    return (
        <div className={'container-report-header'}>
            <div className={'container content'}>
                <div className={'left'}>
                    <span className={'text heading h4'}>
                      Gain Visibility & Insights Into Cloud Security Posture
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ReportHeaderSection;
