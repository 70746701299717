import React, { useState } from "react";
import CountUp, { useCountUp } from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';

interface IProps {
  logo: string
  imgWidth: number
  imgHeight: number
}

const CloudProviderCoverageItem: React.FC<IProps> = (props: IProps) => {
  return (
    <div className={'cloud-providers-numbers-item'}>
      <div className={'image-container'}>
        <img className={'image'} src={props.logo}  alt={'logo'} width={props.imgWidth} height={props.imgHeight} />
      </div>
    </div>
  )
}


export default CloudProviderCoverageItem
