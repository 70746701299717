import React from "react"
import { NavBar, NavBarMobile } from "@/layout"

const PrivacyHeroSection: React.FC = () => {
    return (
        <div className={'container-privacy-hero'}>
            <NavBarMobile/>
            <div className={'container'}>
                <NavBar/>
                <div className={'section-privacy-hero'}>
                    <span className={'text'}>
                        Privacy Policy
                    </span>
                </div>
            </div>
        </div>
    )
}

export default PrivacyHeroSection;
