import React from "react"

interface IProps {
    list: string[]
    className?: string
}

const UnorderedList: React.FC<IProps> = ({list, className}: IProps) => {
    return (
        <ul className={'unordered-list ' + (className !== undefined ? className : '')}
            style={{
                display: 'block',
                listStyleType: 'disc',
                marginBlockStart: '1em',
                marginBlockEnd: '1em',
                marginInlineStart: '0px',
                marginInlineEnd: '0px',
                paddingInlineStart: '40px'
            }}
        >
            { list.map((item, index) => {
                return (
                    <li key={String(index)} className={'unordered-list-item'} style={{listStyleType: 'disc'}}>
                        { item }
                    </li>
                )
            }) }
        </ul>
    )
}

export default UnorderedList

