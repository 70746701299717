import React from "react"
import Placeholder from "@/assets/images/placeholder_fuschia.svg"

const UsecaseEnableNewCloud: React.FC = () => {
    return (
        <div className={'usecase-item'}>
            <div className={'heading-left-paragraph-right'}>
                <div className={'left'}>
                    <span className={'heading'}>
                        Enable New Cloud Services In Your Organisation FAST
                    </span>
                </div>
                <div className={'right'}>
                    <span className={'paragraph'}>
                        Each policy applies the same regardless of which deployment tool is used.
                        By the shifting controls right, integrations with individual tools isn’t required to
                        deliver compliance outcomes. Simplify your deployment tools and reduce rework by decoupling your
                        deployment and governance from each other.
                    </span>
                </div>
            </div>
            <div className={'centre-image'}>
                <div className={'image-container'}>
                    <img className={'image'} alt={'placeholder'} src={Placeholder}/>
                </div>
            </div>
        </div>
    )
}

export default UsecaseEnableNewCloud