import React from 'react'
import Logo from "@/assets/images/kivera-logo-black.svg"
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { PATH_ABOUT_US, PATH_HOME, PATH_PRICING, PATH_PRODUCT, PATH_USECASES } from "@/layout/navigation"

interface IProps {
    className?: string
}

const NavBar: React.FC<IProps> = ({ className }:IProps) => {
    const location = useLocation()

    const isPathActive = (path: string) => {
        return path === location.pathname
    }

    const underlineExpanding = ''

    return (
        <div className={'navbar ' + String(className ?? '')}>
            <div className={'navbar-logo'}>
                <Link to={'/'}>
                    <img className={'navbar-left-logo'} src={Logo} alt={'Kivera logo'} />
                </Link>
            </div>
            <div className={'navbar-middle-menu'}>
                <Link to={PATH_HOME}>
                    <span className={'navbar-middle-menu-item ' + (isPathActive(PATH_HOME) ? 'link-active' : '') + underlineExpanding }>Overview</span>
                </Link>
                <Link to={PATH_PRODUCT}>
                    <span className={'navbar-middle-menu-item ' + (isPathActive(PATH_PRODUCT) ? 'link-active' : '') + underlineExpanding }>Product</span>
                </Link>
                <Link to={PATH_USECASES}>
                    <span className={'navbar-middle-menu-item ' + (isPathActive(PATH_USECASES) ? 'link-active' : '') + underlineExpanding}>Use Cases</span>
                </Link>
                {/*<Link to={PATH_DOCS}>*/}
                {/*    <span className={'navbar-middle-menu-item ' + (isPathActive(PATH_DOCS) ? 'link-active' : '') + underlineExpanding}>Docs</span>*/}
                {/*</Link>*/}
                <Link to={PATH_PRICING}>
                    <span className={'navbar-middle-menu-item ' + (isPathActive(PATH_PRICING) ? 'link-active' : '') + underlineExpanding }>Pricing</span>
                </Link>
                <Link to={PATH_ABOUT_US}>
                    <span className={'navbar-middle-menu-item ' + (isPathActive(PATH_ABOUT_US) ? 'link-active' : '') + underlineExpanding }>About Us</span>
                </Link>
            </div>
            <div className={'navbar-right'}>
                <Link to="/report">
                    <div className={'navbar-right-cta-free-report-button'}>
                        <span className={'navbar-right-cta-free-report-button-text'}>
                            Security Report
                        </span>
                    </div>
                </Link>
                <Link to="/#request">
                    <div className={'navbar-right-cta-button'}>
                        <span className={'navbar-right-cta-button-text'}>
                            Request Demo
                        </span>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default NavBar
