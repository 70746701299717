import React from "react";
import { CloudProviderCoverageItem } from "@/components";
import CoverageAzureImage from "@/assets/images/partners/azure.svg"
import CoverageAWSImage from "@/assets/images/partners/aws.svg"
import CoverageGCPImage from "@/assets/images/partners/gcp.svg"
import PolicyImage from "@/assets/images/policy-image.png"
import CountUp from "react-countup";
import ProviderStats from "/static/provider-stats.json"
import { CoverageTypes } from "@/data";
import VisibilitySensor from 'react-visibility-sensor';

const CoverageSection: React.FC = () => {
  const coverage = ProviderStats as CoverageTypes;

  return (
    <div className={'container-coverage'}>
      <div className={'container section-coverage'}>
        <span className="count">
          <VisibilitySensor onChange={() => {}} delayedCall>
            {() =>
              <CountUp separator="," decimals={0} suffix="+" end={coverage.aws.parameters + coverage.azure.parameters + coverage.gcp.parameters} redraw={true}>
                {({ countUpRef, start }) => (
                  <span className={'count-up'} ref={countUpRef} />
                )}
              </CountUp>
            }
          </VisibilitySensor>
        </span>
        <span className="count-subtext">Kivera Policies (<a href={'https://public.docs.kivera.io'}
          target={'_blank'} className={'thick-link'}>
          View
        </a>)</span>
        <div className={'count-policy-image'}>
          <img className={'image'} src={PolicyImage}  alt={'logo'} width={"100%"}  />
        </div>
        <span className="count-tag-line">
          <span className={'underline-expanding'}>Every</span> <b>Service</b>, <span className={'underline-expanding'}>Every</span> Action, <span className={'underline-expanding'}>Every</span> Parameter
        </span>
        <div className={'cloud-providers-numbers'}>
          <CloudProviderCoverageItem
            logo={CoverageAWSImage}
            imgWidth={100}
            imgHeight={100}
          />
          <CloudProviderCoverageItem
            logo={CoverageAzureImage}
            imgWidth={100}
            imgHeight={100}
          />
          <CloudProviderCoverageItem
            logo={CoverageGCPImage}
            imgWidth={100}
            imgHeight={100}
          />
        </div>
      </div>
    </div>
  )
}

export default CoverageSection;
