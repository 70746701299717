import React from "react"
import Wilson from "@/assets/images/people/wilson_2.png"
import Vernon from "@/assets/images/people/vernon_2.png"
import Neil from "@/assets/images/people/neil_2.png"
import Madhu from "@/assets/images/people/madhu_2.png"
import Vikranth from "@/assets/images/people/vikranth_2.png"
import Tyler from "@/assets/images/people/tyler_2.png"
import John from "@/assets/images/people/john_2.png"
import IconLinkedIn from "@/assets/icons/linkedin.svg"


const AboutUsMeetTheTeamSection: React.FC = () => {
    return (
        <div className={'container-about-us-meet-the-team'}>
            <div className={'container content'}>
                <div className={'top'}>
                    <span className={'text heading h4'}>
                        Meet the Team
                    </span>
                </div>
                <div className={'bottom'}>
                    <div className={'team'}>
                        <div className={'team-member'}>
                            <div className={'team-member-image'}>
                                <img src={Vernon} alt={'vernon'} />
                            </div>
                            <div className={'team-member-text'}>
                                <div className={'team-member-text-name'}>Vernon Jefferson</div>
                                <div className={'team-member-text-title'}>Co-Founder</div>
                                <div className={'team-member-linkedin'}>
                                    <a href={"https://www.linkedin.com/in/vjeffz"} target={"_blank"}>
                                        <img src={IconLinkedIn} alt={'linkedin'}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={'team-member'}>
                            <div className={'team-member-image'}>
                                <img src={Neil} alt={'neil'} />
                            </div>
                            <div className={'team-member-text'}>
                                <div className={'team-member-text-name'}>Neil Brown</div>
                                <div className={'team-member-text-title'}>Co-Founder</div>
                                <div className={'team-member-linkedin'}>
                                    <a href={"https://www.linkedin.com/in/neil-brown1"} target={"_blank"}>
                                        <img src={IconLinkedIn} alt={'linkedin'}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={'team-member'}>
                            <div className={'team-member-image'}>
                                <img src={Wilson} alt={'wilson'} />
                            </div>
                            <div className={'team-member-text'}>
                                <div className={'team-member-text-name'}>Wilson Lee</div>
                                <div className={'team-member-text-title'}>Interim CFO</div>
                                <div className={'team-member-linkedin'}>
                                    <a href={"https://www.linkedin.com/in/wilsonjlee"} target={"_blank"}>
                                        <img src={IconLinkedIn} alt={'linkedin'}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={'team-member'}>
                            <div className={'team-member-image'}>
                                <img src={Madhu} alt={'madhu'} />
                            </div>
                            <div className={'team-member-text'}>
                                <div className={'team-member-text-name'}>Madhu Kumaar</div>
                                <div className={'team-member-text-title'}>Snr Full Stack Engineer</div>
                                <div className={'team-member-linkedin'}>
                                    <a href={"https://www.linkedin.com/in/madhukumaartr--"} target={"_blank"}>
                                        <img src={IconLinkedIn} alt={'linkedin'}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={'team-member'}>
                            <div className={'team-member-image'}>
                                <img src={John} alt={'john'} />
                            </div>
                            <div className={'team-member-text'}>
                                <div className={'team-member-text-name'}>John Chen</div>
                                <div className={'team-member-text-title'}>Senior Software <br/>Developer</div>
                                <div className={'team-member-linkedin'}>
                                    <a href={"https://www.linkedin.com/in/john-chen-2782b01a0"} target={"_blank"}>
                                        <img src={IconLinkedIn} alt={'linkedin'}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={'team-member'}>
                            <div className={'team-member-image'}>
                                <img src={Tyler} alt={'tyler'} />
                            </div>
                            <div className={'team-member-text'}>
                                <div className={'team-member-text-name'}>Tyler Matheson</div>
                                <div className={'team-member-text-title'}>Cloud Engineer</div>
                                <div className={'team-member-linkedin'} style={{
                                    minHeight: "61px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-end"
                                }}>
                                    <a href={"https://www.linkedin.com/in/tyler-matheson"} target={"_blank"}>
                                        <img src={IconLinkedIn} alt={'linkedin'}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={'team-member'}>
                            <div className={'team-member-image'}>
                                <img src={Vikranth} alt={'vikranth'} />
                            </div>
                            <div className={'team-member-text'}>
                                <div className={'team-member-text-name'}>Vikranth Subramanian</div>
                                <div className={'team-member-text-title'}>Cloud Engineer</div>
                                <div className={'team-member-linkedin'}>
                                    <a href={"https://www.linkedin.com/in/vikranth-subramanian"} target={"_blank"}>
                                        <img src={IconLinkedIn} alt={'linkedin'}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsMeetTheTeamSection;
