import React from "react"
import ManOnComputer from "@/assets/images/man-on-computer.svg"
import { COLOR_LIGHT_GREY } from "@/styles/css-in-js"
import { SectionImageText } from "@/components"

const ComplianceAsCodeSection: React.FC = () => {
    return (
        <div className={'container-compliance'} style={{backgroundColor: COLOR_LIGHT_GREY}}>
            <SectionImageText
                className={'compliance-as-code'}
                img={ManOnComputer}
                imgAlt={'compliance-as-code-image'}
                imgClassName={'compliance-as-code-image'}
                title={<>
                    <span className={'underline-expanding'}>Empower</span> Developers and Accelerate Innovation
                </>}
                paragraph={<>
                    As organisational cloud maturity increases, developers seek tooling better aligned to their workloads.
                    Enabling and supporting these new cloud tools while maintaining security posture is a challenge.
                    With Kivera, codify your threat models as Kivera Policies then universally apply them across every
                    tool whether using the CLI, Terraform, Jenkins, Codefresh, Pulumi, Bitbucket Pipelines, cloud native
                    templates and more. Support developers' tooling of choice and maintain your security posture.
                </>}
                reverse={true}
            />
        </div>
    )
}

export default ComplianceAsCodeSection;
