import React from "react"
import { Paragraph, UnorderedList } from "@/components"

const PrivacyCookiesSection: React.FC = () => {
    return (
        <div className={'container-cookies-policy'}>
            <div className={'container'}>
                <div className={'section-cookies-policy'}>
                    <h3 className={'paragraph medium bold'}>Cookies Policy</h3>

                    <Paragraph
                        title={'Introduction'}
                        paras={[
                            'In addition to any information that you choose to submit to us, we or selected third parties may use a variety of technologies that store or access information already stored on your personal computer, lap top, tablet, personal data device, or other device that access the website (each a “device”) whenever you visit or interact with our website.',
                        ]}/>

                    <Paragraph
                        title={'The Information Collection Technologies'}
                        paras={[
                        'A few of the methods that may be used to store or access information already stored on your device include the following (and in future other technology and methods will be developed; we do not currently use all of the below but may in the future):'
                    ]}/>

                    <UnorderedList
                        className={'paragraph small'}
                        list={[
                         'Cookies — A cookie is a text file placed on a device when a user visits a website. Cookies can be temporary (e.g., session) or permanent (e.g., persistent). They can also be first party (e.g., placed by us) or third party (e.g., placed by a third party advertiser or advertisements server);',
                         'Flash Cookies — A Flash cookie (or locally shared object) is a data file placed on your device via the Adobe Flash plug-in that may be built-in to or downloaded by you to your device. This can operate across all your browsers.',
                         'HTML5 — HTML5 cookies can be programmed through HTML5 local storage and do not require a plug-in.',
                         'ETag or Entity Tag — An ETag is an opaque identifier assigned by a web server to a specific version of a resource found at a URL that acts as a form of device identifier.'
                        ]}
                    />

                    <Paragraph
                        title={'Cookie’s Used By Us'}
                        paras={[
                            'We use a variety of internal and third party cookies relating to your use and performance of our site and advertising to you, some of which collect Personal Information. The cookies we use are updated on a regular basis and are set out below and categorized by function.'
                        ]}
                    />

                    <Paragraph
                        title={'Strictly Necessary Cookies'}
                        paras={[
                            'The following cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any Personal Information.'
                        ]}
                    />

                    <Paragraph
                        title={'Cookies Used'}
                        titleOnly={true}
                        paras={['']}
                        noSpace={true}
                    />

                    <UnorderedList
                        className={'paragraph small'}
                        list={[
                            'None'
                        ]}
                    />

                    <Paragraph
                        title={'Performance Cookies'}
                        paras={[
                            'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous and does not contain identifiable Personal Information. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.'
                        ]}
                    />
                    <Paragraph
                        title={'Cookies Used'}
                        titleOnly={true}
                        paras={['']}
                        noSpace={true}
                    />

                    <UnorderedList
                        className={'paragraph small'}
                        list={[
                            'None'
                        ]}
                    />

                    <Paragraph
                        title={'Targeting Cookies'}
                        paras={[
                            'These cookies may be provided through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant advertisements on other sites. The type Personal Information that they use and store is based on uniquely identifying your browser, mobile network information, operating system, and the device that you use to access the site and browse the Internet. If you do not allow these cookies, you will experience less targeted advertising.'
                        ]}
                    />

                    <Paragraph
                        title={'Cookies Used'}
                        titleOnly={true}
                        paras={['']}
                        noSpace={true}
                    />

                    <UnorderedList
                        className={'paragraph small'}
                        list={[
                            'None'
                        ]}
                    />

                </div>
            </div>
        </div>
    )
}

export default PrivacyCookiesSection;
